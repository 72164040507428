#minhasEmpresas {

    padding: 6px;
    border-radius: 6px;

    .ant-layout-content {
        height: 100%;
        min-height: 100%;
    }

}


.ReactModal__Content.ReactModal__Content--after-open.modalContent.avatarLogoEmpresa {
    .ReactCrop__crop-selection {

    }
}

