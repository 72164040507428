@import "../../assets/css/variaveis";

.meus-usuario-container {

    padding: 6px;
    border-radius: 6px;

    .ant-transfer-list {
        width: 47%;
    }

}
