.uploadLoadingPosition {

    width: 100%;
    position: relative;

    .uploadBackdropLoading {
        z-index: 999;
        background-color:  rgba(0, 0, 0, 0.75);
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
    }

}

#backdropLoadingUpload {
    background-color:  rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 100;

    div.box-spinner {

        background-color: #c0c0c0;
        border: solid 1px white;
        border-radius: 5px;
        padding: 20px;
        text-align: center;

        label {
            margin-top: 10px;
            display: block;
            font-size: 20px;
        }

    }

}


