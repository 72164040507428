body.iy2b-app-body #root {
    button.ant-btn.token {
        color: #EEE9F7 !important;
        background-color: #8267C6 !important;
        text-transform: unset !important;
        .ant-btn-icon {
            font-size: 16px;
            .svg-inline--fa {
                color: #EEE9F7 !important;
            }
        }
    }
}

#root .ant-layout button.ant-btn.token:focus,
#root .ant-layout button.ant-btn.token:active,
#root .ant-layout button.ant-btn.token:hover, {
    background-color: #D3CAEB !important;
    color: #8267C6 !important;
    border-color: #8267C6 !important;
    .ant-btn-icon {
        .svg-inline--fa {
            color: #8267C6 !important;
        }
    }
}
