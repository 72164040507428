@import "../../../assets/css/breakpoints";

#containerPesquisaWS {

    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    #boxPesquisaWS {

        width: 300px;

        @include xl {
            width: 340px;
        }

        @include xxl {
            width: 380px;
        }

        padding: 0px;
        height: 64px;

        display: flex;
        align-items: center;
        background-color: white;
        border-radius: 40px;
        box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;

        .ant-select-selector {
            margin-top: -4px;
            .ant-input-wrapper {
            }
        }

        .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
            border: none;
            box-shadow: none;
            height: 40px;
            padding-top: 6.5px;
            padding-right: 0px;
            padding-bottom: 6.5px;
            padding-left: 12px;
            background-color: #EFEFEF;
            input.ant-input {
                background-color: #EFEFEF;
            }
        }
        .ant-input:focus {
            border: none !important;
        }
        .ant-input-group-addon {
            border-top-right-radius: 40px;
            border-bottom-right-radius: 40px;
            button {
                background-color: #EFEFEF !important;
                border-color: #EFEFEF !important;
                border-top-right-radius: 40px;
                border-bottom-right-radius: 40px;
                color: #656565;
                font-weight: 400;
                font-size: 20px;
                .ant-btn-icon {
                }
            }
        }

    }

}

