@import "../../assets/css/variaveis";
@import "../../assets/css/breakpoints";

body.popUpWizNovoContrato {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 0px 0px 0px 0px;

        .modal-header {
            text-align: center;
            background-color: $primaryColorDark;
            padding: 10px;
            color: $primaryBgColor;
            font-weight: bold;
            font-size: 16px;
            height: 52px;
            div.top-bar {
                position: absolute;
                right: 10px;
            }
        }

        .box-body {
            background-color: $primaryBgColor;
            padding: 15px;

            .ant-pro-steps-form-container {
                min-width: 620px;
                @include lg {
                    min-width: 720px;
                }
            }

            .grid-planos {

                min-width: 290px;

                @include md {
                    min-width: 600px;
                }

                @include lg {
                    min-width: 900px;
                }

                @include xl {
                    min-width: 1100px;
                }

                .ant-tabs {

                    .ant-tabs-nav-list {

                        background-color: whitesmoke;

                        min-width: 148px;
                        max-width: 148px;
                        width: 148px;

                        @include md {
                            min-width: 198px;
                            max-width: 198px;
                            width: 198px;
                        }

                        .ant-tabs-tab {

                            margin: 8px 0 0 0;
                            .ant-tabs-tab-btn {
                                font-size: 12px;
                                font-weight: bold;
                                @include md {
                                    font-size: 16px;
                                    font-weight: normal;
                                }
                            }
                        }

                        .ant-tabs-tab.ant-tabs-tab-active {
                            .ant-tabs-tab-btn {
                                font-weight: bold;
                            }
                        }

                    }

                    .ant-tabs-tabpane {

                        min-width: 215px;
                        max-width: 215px;
                        width: 215px;

                        @include md {
                            min-width: 535px;
                            max-width: 535px;
                            width: 535px;
                        }

                        @include lg {
                            min-width: 705px;
                            max-width: 705px;
                            width: 705px;
                        }

                        @include xl {
                            min-width: 980px;
                            max-width: 980px;
                            width: 980px;
                        }

                        display: flex;
                        padding-left: 6px;

                        .row-box-planos {
                            width: 100%;
                        }

                    }

                    .ant-tabs-tabpane.ant-tabs-tabpane-hidden {
                        display: none;
                    }

                }

                .box-preco:hover {
                    cursor: pointer;
                }

                .box-preco {

                    border-radius: 5px;
                    text-align: center;
                    padding: 5px;
                    margin: 0px 2px 0px 2px;
                    min-height: 305px;
                    background-color: white;

                    @media screen and (min-height: 635px) {
                        height: 365px;
                    }

                    .nome,
                    .formaPagto,
                    .total,
                    .descricao {
                        display: block;
                        width: 100%;
                    }

                    .body-preco {
                        height: calc(100% - 34px);
                    }

                    .toolbar {
                        bottom: 15px;
                        left: 2px;
                        padding: 0px 15px 0px 12px;
                    }

                    .nome {
                        border-bottom: solid 1px darkred;

                        font-size: 16px;
                        min-height: 70px;

                    }

                    .formaPagto {
                        color: #f69320;
                        font-size: 16px;
                    }

                    .total {
                        font-weight: lighter;
                        font-size: 16px;
                    }

                    .descricao {
                        text-align: justify;
                        font-weight: bolder;
                        min-height: 75px;
                        max-height: 95px;
                        font-size: 12px;
                        overflow: hidden;
                        @include md {
                            @media screen and (min-height: 635px) {
                                min-height: 110px;
                                max-height: 110px;
                            }

                        }

                    }

                    div.containerItensPlano {

                        height: 290px;
                        overflow-x: auto;
                        margin-bottom: 10px;

                        div.itens {

                            text-align: left;

                            div.item {
                                margin-top: 0px;
                                padding-top: 0px;
                                margin-bottom: 4px;

                                span {
                                    margin-left: 5px;
                                    font-size: 12px;
                                    @include md {
                                        font-size: 14px;
                                    }
                                }
                            }

                        }

                    }

                }

            }

            .containerStepCertDig {
                max-width: 720px;
                overflow-y: auto;
                overflow-x: hidden;
                height: 310px;
                @media (min-height: 768px) {
                    height: 400px;
                }
            }

            .containerStepCadEmpresa {
                overflow-y: auto;
                overflow-x: hidden;
                height: 320px;
                @media (min-height: 768px) {
                    height: 400px;
                }
            }

            .ant-pro-steps-form-container {
                .ant-space.ant-space-horizontal.ant-space-align-center {
                    margin-top: 8px;
                }
            }

            .ant-upload-drag {
                border: solid 1px #CCC;
                padding: 30px;
            }

            .rowListaReqs {

                margin:  5px;

            }

            .rowCertDig, .rowSenha, .rowInfoCertD, .rowAceite {

                margin: 5px 5px 15px 5px;

            }

        }

        .modal-footer {
            background-color: $primaryColorDark;
            padding: 5px;
        }

    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}
