.iy2b-lista-requisitos {

    label.ant-checkbox-wrapper {

        margin-top: 8px;

        .ant-checkbox-disabled + span {
            color: red !important;
        }

        .ant-checkbox-checked.ant-checkbox-disabled + span {
            color: darkgreen !important;
        }

    }

}
