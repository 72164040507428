
#boxNotificacoesDropdown {
    height: 100%;
    padding: 0px 6px 0px 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-badge {
        .svg-inline--fa {
            font-size: 32px;
        }
        .ant-badge-count {
            background: #E00000;
        }
    }
}

body.notificacoesDropdownModal {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2000;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: hidden;
        outline: none;
        padding: 0px 0px 0px 0px;
        top: 64px;
        z-index: 2001;
        height: 561px;

        border: solid 1px #333;

        .header {
            padding: 16px 8px;
            background-color: #EEE9F7;
            border-bottom: 1px solid #333;
            display: flex;
            align-items: center;
            .label {
                width: calc(100% - 24px);
                display: inline-block;
                text-align: center;
                font-size: 20px;
            }
            .botao {
                width: 24px;
                height: 24px;
                display: inline-block;
            }
        }
        .scroller {
            overflow-x: auto;
            background: #EEE9F7;
            border-radius: 0px;
            padding: 0px 8px;
            height: 430px;
            .ant-list-item-meta-avatar {
                font-size: 20px;
            }
        }
        .footer {
            padding: 16px 8px;
            background-color: #EEE9F7;
            border-bottom: 1px solid #333;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-top: 1px solid #333;
        }

    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}
