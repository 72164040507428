.iy2b-autocomplete-pessoa {


}

.dropdown-iy2b-autocomplete-pessoa-nome {
    font-size: 8px !important;
}

.iy2b-pro-field.not-visible {
    display: none;
}

.container-botao-add {
    margin: 8px;
    font-size: 16px;
}

