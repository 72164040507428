.editar-checklist-pessoa {
    .body-checklist-pessoa {
        height: 100%;
        .ant-tree.ant-tree-show-line {
            background-color: transparent;
            .ant-tree-treenode {
                .ant-tree-switcher {
                    background-color: transparent;
                }
            }
        }
    }
}

