body.iy2b-app-body #root {
    button.ant-btn.facebook {
        color: white !important;
        background-color: #3b5998 !important;
        text-transform: unset !important;
        .ant-btn-icon {
            font-size: 18px !important;
            .svg-inline--fa {
                color: white !important;
            }
        }
    }
}
.tem-desvinc .facebook {
    width: calc(100% - 36px);
    margin-right: 2px;
}

#root .ant-layout button.ant-btn.facebook:focus,
#root .ant-layout button.ant-btn.facebook:active,
#root .ant-layout button.ant-btn.facebook:hover, {
    background-color: white !important;
    color: #3b5998 !important;
    border-color: #3b5998 !important;
    .ant-btn-icon {
        .svg-inline--fa {
            color: #3b5998 !important;
        }
    }
}

#root .ant-layout button.ant-btn.facebook[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}
