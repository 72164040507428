.form-endereco {

    .ant-row.header {
        margin-top: 10px;
        margin-bottom: 5px;

        label {
            font-size: 16px;
            font-weight: bold;
        }

    }

}
