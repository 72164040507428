@import "../../../assets/css/breakpoints";

.workspace {
    .ant-layout, .ant-modal-root {
        #headerWS.ant-layout-header {
            background-color: #000;
        }
    }
}


.workspace.debug-habilitado {

    .ant-layout, .ant-modal-root {

        #headerWS.ant-layout-header {
            background-color: red;
        }

    }

}

#headerWS {
    height: 80px;
    width: 100%;
    padding: 0px !important;

    .headerContainerBotaoWizard {
        /* z-index: 3500; */
    }

    .right-headerws {
        flex: auto;
        display: contents;
        .display-contents.app-logo {
            .ant-image {
                margin: 0px;
                width: unset !important;
                height: unset !important;
            }
        }
        .ant-image {
            margin: 0px 5px 0px 5px;
        }
    }

}

body.menuSuspensoModal {
    #headerWS {
        .right-headerws {
            background: #666666;
        }
    }
}

