@import "../../framework/assets/css/breakpoints";
@import "../../framework/assets/css/variaveis";

#root #wizardContratar .ant-layout {

    .ant-layout-header {
        background-color: $primaryBgColor;
        position: fixed;
        z-index: 1;
        width: 100%;
        padding: 6px;

        @include md {
            padding: 0 25px;
        }

        @include lg {
            padding: 0 50px;
        }

        .logo {
            width: 161px;
            height: 46px;
            margin-top: 5px;
            border-radius: 15px;

            @include md {
                width: 189px;
                height: 54px;
            }

        }

        label {

            font-size: 14px;
            color: #04ac2b;

            @include md {
                font-size: 16px;
            }

            @include lg {
                font-size: 20px;
            }
        }
    }

    .ant-layout-content {

        padding: 10px;

        .ant-pro-steps-form {
            margin-top: 64px;

            .ant-descriptions-item-content {
                font-weight: bold;
            }

        }

    }

}

body.iy2b-mobile #root #wizardContratar {

    .ant-pro-steps-form {

        .ant-row.grid-planos {

            .box-preco {

                background-color: white;

            }

        }

    }

}

#root #wizardContratar {

    .ant-pro-card {

        height: 100%;

        .ant-pro-steps-form {

            height: 100%;
        }

    }

    .ant-pro-card, .ant-layout-content {

        .ant-pro-steps-form {

            .ant-pro-steps-form-container {

                .ant-space.ant-space-horizontal.ant-space-align-center {
                    margin-top: 8px;
                }

                button {
                    background-color: #38c593;
                    border-color: #38c593;
                    color: #e6f5f0;
                }

                button:hover, button:active, button:focus {
                    background-color: #e6f5f0;
                    border-color: #38c593;
                    color: #38c593;
                }

            }

            .ant-pro-steps-form-step {

                @include md {

                    overflow-x: auto;

                    height: 370px;

                    @media screen and (min-height: 635px) {
                        height: 390px;
                    }

                }

            }

            .ant-steps-item.ant-steps-item-process.ant-steps-item-active {
                .ant-steps-item-icon {
                    background-color: #38c593;
                }
            }
            .ant-steps-item.ant-steps-item-process {
                .ant-steps-item-icon {
                    background-color: #38c593;
                    border-color: #38c593;
                }
            }
            .ant-steps-item.ant-steps-item-finish {
                .ant-steps-item-icon {
                    color: #38c593;
                    border-color: #38c593;
                }
                .ant-steps-item-content {
                     .ant-steps-item-title::after {
                         background-color: #38c593;
                     }
                }
            }

            .grid-planos {

                background-color: #e6f5f0;
                border: solid 1px #38c593;

                min-width: 290px;
                max-width: calc(100% - 142px);

                @include md {
                    min-width: 600px;
                }

                @include lg {
                    min-width: 900px;
                }

                @include xl {
                    min-width: 1100px;
                }

                .ant-tabs {

                    .ant-tabs-content-holder {
                        padding: 0;
                    }

                    .ant-tabs-nav {
                        margin-bottom: 0px;
                    }

                    .ant-tabs-nav-list {

                        background-color: #38c593;

                        min-width: 148px;
                        max-width: 148px;
                        width: 148px;

                        @include md {
                            min-width: 180px;
                            max-width: 180px;
                            width: 180px;
                        }

                        .ant-tabs-tab {

                            color: white;
                            background-color: #38c593;

                            margin: 8px 0 0 0;

                            .ant-tabs-tab-btn {
                                font-size: 12px;
                                font-weight: bold;
                                @include md {
                                    font-size: 16px;
                                    font-weight: normal;
                                }
                            }

                        }

                        .ant-tabs-tab.ant-tabs-tab-active {

                            background-color: #e6f5f0;
                            border: none;

                            .ant-tabs-tab-btn {
                                color: black;
                                font-weight: bold;
                            }
                        }

                    }

                    .ant-tabs-tabpane {

                        /*
                        min-width: 235px;
                        max-width: 235px;
                        width: 235px;

                        @include md {
                            min-width: 535px;
                            max-width: 535px;
                            width: 535px;
                        }

                        @include lg {
                            min-width: 805px;
                            max-width: 805px;
                            width: 805px;
                        }

                        @include xl {
                            min-width: 973px;
                            max-width: 973px;
                            width: 973px;
                        }
                        */

                        display: flex;
                        padding-left: 6px;

                        .row-box-planos {
                            width: 100%;
                        }

                    }

                    .ant-tabs-tabpane.ant-tabs-tabpane-hidden {
                        display: none;
                    }

                }

                .box-preco:hover {
                    cursor: pointer;
                }

                .box-preco.selecionado {
                }

                .box-preco {

                    text-align: center;
                    padding: 5px;
                    margin: 0px 2px 10px 2px;
                    min-height: 335px;
                    /* background-color: white; */

                    @media screen and (min-height: 635px) {
                        height: 365px;
                    }

                    .descricao {
                        padding-right: 24px;
                        padding-left: 24px;
                    }

                    .nome,
                    .formaPagto,
                    .total,
                    .descricao {
                        display: block;
                        width: 100%;
                    }

                    .toolbar {
                        position: absolute;
                        bottom: 15px;
                        left: 2px;
                        padding: 0px 15px 0px 12px;
                        width: 100%;
                        button {
                            min-height: 60px;
                            font-size: 16px;
                            @include md {
                                font-size: 20px;
                            }
                            border-radius: 15px;
                        }
                        button.jaEscolhido {
                            background-color: #04ac2b;
                            border-color: #04ac2b;
                        }
                        button.jaEscolhido:hover, button.jaEscolhido:active, button.jaEscolhido:focus{
                            background-color: #04ac2b;
                            border-color: #04ac2b;
                            color: #f8f8f8;
                        }
                    }

                    .nome {
                        font-size: 16px;
                        min-height: 70px;
                        font-weight: bold;
                        padding-top: 12px;

                        @include md {
                            font-size: 20px;
                            min-height: 90px;
                        }

                        @include lg {
                            font-size: 24px;
                            min-height: 110px;
                        }

                    }

                    .precoBase {
                        font-size: 12px;
                        text-decoration: line-through;
                        font-style: italic;
                    }

                    .formaPagto {
                        color: #f69320;
                        margin-bottom: 20px;
                        font-size: 16px;
                        color: #04ac2b;
                        @include md {
                            font-size: 20px;
                        }
                        @include lg {
                            font-size: 24px;
                        }
                        .periodicidade {
                            font-size: 12px;
                            color: black;
                        }
                        .valor {
                            font-weight: bold;
                        }
                    }

                    .total {
                        font-weight: lighter;
                        font-size: 16px;
                        @include md {
                            font-size: 20px;
                        }
                        @include lg {
                            font-size: 24px;
                        }
                    }

                    .descricao {
                        text-align: justify;
                        font-weight: normal;
                        min-height: 90px;
                        max-height: 90px;
                        font-size: 12px;
                        overflow: hidden;
                        @include md {
                            font-size: 14px;
                            min-height: 80px;
                            max-height: 80px;

                            @media screen and (min-height: 635px) {
                                min-height: 110px;
                                max-height: 110px;
                            }

                        }

                    }

                }

            }

        }

    }

}

.body-mais-detalhes-plano-contratovenda {

    .box-preco:hover {
        cursor: pointer;
    }

    .box-preco {

        padding: 5px;
        margin: 0px 2px 10px 2px;
        min-height: 350px;

        .nome,
        .formaPagto,
        .total,
        .descricao {
            text-align: center;
            display: block;
            width: 100%;
        }

        .toolbar {
            position: absolute;
            bottom: 15px;
            left: 2px;
            padding: 0px 15px 0px 12px;
            width: 100%;
        }

        .nome {
            border-bottom: solid 1px darkred;

            font-size: 16px;
            min-height: 40px;
            margin-bottom: 10px;

            @include md {
                font-size: 20px;
                min-height: 40px;
            }

            @include lg {
                font-size: 24px;
                min-height: 50px;
            }

        }

        .formaPagto {
            color: #f69320;
            font-size: 16px;
            @include md {
                font-size: 20px;
            }
            @include lg {
                font-size: 24px;
            }
        }

        .total {
            font-weight: lighter;
            font-size: 16px;
            @include md {
                font-size: 20px;
            }
            @include lg {
                font-size: 24px;
            }
        }

        .descricao {
            text-align: justify;
            font-weight: lighter;
            min-height: 40px;
            font-size: 14px;
            @include md {
                font-size: 16px;
                min-height: 50px;
            }

        }

        div.containerItensPlano {

            min-height: 185px;
            overflow-x: auto;
            margin-bottom: 10px;

            div.itens {

                min-height: 185px;
                text-align: left;
                display: inline-table;

                div.item {
                    margin-top: 0px;
                    padding-top: 0px;
                    margin-bottom: 4px;
                    margin-right: 10px;

                    span {
                        margin-left: 5px;
                        font-size: 12px;
                        @include md {
                            font-size: 14px;
                        }
                    }
                }

            }

        }

    }

}
