#headerWS {

    .ant-row.header-toolbar {
        margin-left: 12px;
        margin-right: 4px;
        align-items: center;
        overflow: hidden;
        height: 78px;
        justify-content: space-between;

        #right-icons {
            box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: white;
            border-radius: 40px;
            margin-left: 12px;
            .svg-inline--fa {
                color: #656565;
            }
        }
    }

}
