.twitter {
    color: white;
    background-color: #55acee;
}

.tem-desvinc .twitter {
    width: calc(100% - 36px);
    margin-right: 2px;
}

#root .ant-layout .ant-btn.twitter:focus,
#root .ant-layout .ant-btn.twitter:active,
#root .ant-layout .ant-btn.twitter:hover, {
    background-color: white;
    color: #55acee;
    border-color: #55acee;
}

#root .ant-layout .ant-btn.twitter[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}
