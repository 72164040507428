//
// ethimo
//
@import "../breakpoints";

/*
XS=104px for short numbers, short text or options.
S=216px for shorter field entries, such as name, phone, ID, etc.
M=328px Standard width, suitable for most field lengths.
L=440px Suitable for longer field entries, such as long URLs, tag groups, file paths, etc.
XL=552px
XXL=660
 */

$antBtnPrimaryEthimo: #9982D1;
$antBtnDangerEthimo: #E00000;
$antBtnDefaultEthimo: #F8F8F8;
$whiteColorEthimo: #FFF;

.ethimo {
    --ant-font-family: "Space Grotesk", sans-serif;
    --ant-color-primary: #E3F7EE;
    --ant-color-info: #9982D1;
    --ant-color-link: #9982D1;
    --ant-color-error: #E00000;
    --ant-color-success: #00C48C;
    --ant-color-bg-container-disabled: #EFEFEF;
    --ant-color-text-disabled: #ADADAD;
}
.ethimo.ant-select-css-var {
    --ant-select-option-active-bg: #D3CAEB;
    --ant-select-option-selected-bg: #F8F8F8;
}
.ethimo.ant-dropdown-css-var {
    --ant-control-item-bg-hover: #E3F7EE;
}
.ethimo.ant-btn {
    --ant-color-primary-hover: #8267C6;
    --ant-color-error-hover: #C00000;
}
.ethimo.ant-layout {
    --ant-layout-body-bg: #F8F8F8;
    --ant-layout-sider-bg: #F8F8F8;
}
.ethimo.ant-menu-css-var {
    --ant-menu-item-color: #8267C6;
    --ant-menu-item-selected-color: #9982D1;
    --ant-menu-item-hover-bg: rgba(0, 0, 0, 0);
    --ant-menu-item-hover-color: red;
}
.ethimo.ant-radio-css-var {
    --ant-radio-radio-bg-color: #F8F8F8;
}
.ethimo.ant-progress {
    --ant-progress-default-color: #8267C6;
}
.ethimo.ant-tree {
    --ant-tree-node-selected-bg: #D3CAEB;
}

body.ethimo.iy2b-app-body {
    font-family: "Space Grotesk", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    .ant-statistic {
        .ant-statistic-title {
            font-family: "Space Grotesk", sans-serif;
            font-optical-sizing: auto;
        }
        .ant-statistic-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: "Space Grotesk", sans-serif;
            font-optical-sizing: auto;
            .ant-statistic-content-value {
                width: 100%;
                text-align: right;
            }
        }
    }
    .ant-tree {
        .ant-tree-title {
            font-family: "Space Grotesk", sans-serif;
            font-optical-sizing: auto;
        }
    }
    .ant-form {
        .ant-form-item-label {
            label {
                font-family: "Space Grotesk", sans-serif;
                font-optical-sizing: auto;
            }
        }
        .ant-form-item-control-input-content {
            .ant-input {
                font-family: "Space Grotesk", sans-serif;
                font-optical-sizing: auto;
            }
            .ant-select-selector {
                input {
                    font-family: "Space Grotesk", sans-serif;
                    font-optical-sizing: auto;
                }
            }
        }
    }
    .ant-table {
        .ant-table-header {
            thead {
                .ant-table-column-title {
                    font-family: "Space Grotesk", sans-serif;
                    font-optical-sizing: auto;
                }
            }
        }
        tbody {
            tr {
                td.ant-table-cell {
                    span {
                        font-family: "Space Grotesk", sans-serif;
                        font-optical-sizing: auto;
                    }
                }
            }
        }
    }
    .ant-descriptions {
        .ant-descriptions-item-container {
            .ant-descriptions-item-content {
                font-family: "Space Grotesk", sans-serif;
                font-optical-sizing: auto;
            }
            .ant-descriptions-item-label {
                font-family: "Space Grotesk", sans-serif;
                font-optical-sizing: auto;
            }
        }
    }
    .ant-card {
        .ant-card-head {
            .ant-card-head-wrapper {
                .ant-card-head-title {
                    font-family: "Space Grotesk", sans-serif;
                    font-optical-sizing: auto;
                }
            }
        }
    }
    .ant-tabs {
        .ant-tabs-nav {
            .ant-tabs-nav-list {
                .ant-tabs-tab.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        font-family: "Space Grotesk", sans-serif;
                        font-optical-sizing: auto;
                    }
                }
            }
        }
    }
    .ant-list-item {
        font-family: "Space Grotesk", sans-serif;
        font-optical-sizing: auto;
        .ant-list-item-meta-title {
            font-family: "Space Grotesk", sans-serif;
            font-optical-sizing: auto;
        }
        .ant-list-item-meta-description {
            font-family: "Space Grotesk", sans-serif;
            font-optical-sizing: auto;
        }
    }
    .ant-collapse {
        .ant-collapse-header {
            .ant-collapse-header-text {
                font-family: "Space Grotesk", sans-serif;
                font-optical-sizing: auto;
            }
        }
    }
    .ant-select {
        .ant-select-selection-item {
            font-family: "Space Grotesk", sans-serif;
            font-optical-sizing: auto;
        }
        .ant-select-selection-placeholder {
            font-family: "Space Grotesk", sans-serif;
            font-optical-sizing: auto;
        }
    }
    .ant-select-dropdown {
        .ant-select-item-option-content {
            font-family: "Space Grotesk", sans-serif;
            font-optical-sizing: auto;
        }
        .ant-pro-select-item-option-content-light {
            color: #6348B5;
        }
    }
    .ant-radio-group {
        .ant-radio-wrapper {
            font-family: "Space Grotesk", sans-serif;
            font-optical-sizing: auto;
        }
    }
    .ant-checkbox-group {
        .ant-checkbox-wrapper {
            font-family: "Space Grotesk", sans-serif;
            font-optical-sizing: auto;
        }
    }
    .ReactModalPortal {
        .modalOverlay {
            .modalContent {
                .modal-header {
                    .title {
                        font-family: "Space Grotesk", sans-serif;
                        font-optical-sizing: auto;
                    }
                }
                .ant-card {
                    .ant-card-body {
                        .header {
                            font-family: "Space Grotesk", sans-serif;
                            font-optical-sizing: auto;
                        }
                    }
                }
            }
        }
    }
    ul.ant-dropdown-menu {
        li.ant-dropdown-menu-item {
            .ant-dropdown-menu-title-content {
                font-family: "Space Grotesk", sans-serif;
                font-optical-sizing: auto;
            }
        }
    }
    .ant-tooltip {
        .ant-tooltip-content {
            .ant-tooltip-inner {
                font-family: "Space Grotesk", sans-serif;
                font-optical-sizing: auto;
            }
        }
    }
    button.ant-btn {
        font-family: "Space Grotesk", sans-serif;
        font-optical-sizing: auto;
    }
    background-color: #F8F8F8;
    .ant-input,
    .ant-input-affix-wrapper {
        background-color: $whiteColorEthimo;
    }
    .ant-input[disabled],
    .ant-input-number-input[disabled],
    .ant-input-affix-wrapper-disabled,
    .ant-input-affix-wrapper[disabled] {
        background-color: #EFEFEF;
    }
    .ant-input:hover,
    .ant-input:focus {
        border-color: $antBtnPrimaryEthimo;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: $antBtnPrimaryEthimo;
    }
    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
        border-color: $antBtnPrimaryEthimo;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: $antBtnPrimaryEthimo;
    }
    .ant-picker:hover,
    .ant-picker-focused {
        border-color: $antBtnPrimaryEthimo;
    }
    .ant-picker-input > input:focus,
    .ant-picker-input > input-focused {
        border-color: $antBtnPrimaryEthimo;
    }
    .ant-menu-light {
        .ant-menu-title-content {
            color: #141414;
        }
        .ant-menu-submenu:hover {
            .ant-menu-submenu-title {
                .ant-menu-submenu-arrow {
                    color: $antBtnPrimaryEthimo;
                }
            }
        }
        .ant-menu-title-content:hover,
        .ant-menu-item:hover {
            color: $antBtnPrimaryEthimo;
        }
    }
    .ant-switch-checked {
        background-color: $antBtnPrimaryEthimo;
    }
    .ant-switch-checked:hover {
        background-color: #9747FF;
    }
    .ant-picker-range .ant-picker-active-bar {
        background: #8267C6;
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background: #8267C6;
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: #EEE9F7;
    }
    .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
        color: #8267C6;
        background: #EEE9F7;
        border-color: #B6A6DE;
    }
    .ant-radio-checked {
        .ant-radio-inner {
            border-color: #9747FF;
            background-color: #9747FF;
        }
        .ant-radio-inner::after {
            background-color: #FFF;
            width: 24px;
            height: 24px;
            inset-block-start: 20%;
            inset-inline-start: 20%;
        }
    }
    .ant-radio-checked::after {
        border: solid 1px #8267C6;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
            border-color: $antBtnPrimaryEthimo;
        }
    }
    #root .ant-layout .ant-btn-primary,
    div.ant-popover.ant-popconfirm .ant-btn-primary,
    div.ant-modal-root .ant-btn-primary,
    div.ant-drawer-open .ant-btn-primary,
    div.ReactModalPortal .ant-btn-primary {
        background-color: #E3F7EE;
        border-color: #00A96C;
        color: #00A96C;
        font-weight: 700;
        text-transform: uppercase;
        .svg-inline--fa {
            color: #525252;
            colorX: #00A96C;
            .fa-primary {
                color: #525252;
                opacity: 1.0;
            }
            .fa-secondary {
                color: #525252;
                opacity: 1.0;
            }
        }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $antBtnPrimaryEthimo;
        border-color: $antBtnPrimaryEthimo;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner {
        border: solid 2px $antBtnPrimaryEthimo;
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $antBtnPrimaryEthimo;
    }

    #root .ant-layout .ant-btn-primary.ant-btn-dangerous,
    div.ant-popover.ant-popconfirm .ant-btn-primary.ant-btn-dangerous,
    div.ant-modal-root .ant-btn-primary.ant-btn-dangerous,
    div.ant-drawer-open .ant-btn-primary.ant-btn-dangerous,
    div.ReactModalPortal .ant-btn-primary.ant-btn-dangerous,
    #root .ant-layout .ant-btn.ant-btn-dangerous,
    div.ant-popover.ant-popconfirm .ant-btn.ant-btn-dangerous,
    div.ant-modal-root .ant-btn.ant-btn-dangerous,
    div.ant-drawer-open .ant-btn.ant-btn-dangerous,
    div.ReactModalPortal .ant-btn.ant-btn-dangerous,
    #root .ant-layout .ant-btn-danger,
    div.ant-modal-root .ant-btn-danger,
    div.ant-drawer-open .ant-btn-danger,
    div.ReactModalPortal .ant-btn-danger {
        background-color: $antBtnDangerEthimo;
        border-color: $antBtnDangerEthimo;
        color: #FFFFFF;
        font-weight: 700;
        text-transform: uppercase;
        .svg-inline--fa {
            color: #FFFFFF;
        }
    }

    #root .ant-layout .ant-btn-default,
    div.ant-modal-root .ant-btn-default,
    div.ant-drawer-open .ant-btn-default,
    div.ReactModalPortal .ant-btn-default {
        font-weight: 700;
        text-transform: uppercase;
        background-color: transparent;
        color: #00C48C;
        border: none;
        box-shadow: none;
        .svg-inline--fa {
            color: #525252;
            Xcolor: #00C48C;
        }
    }
    #root .ant-layout .ant-btn-link,
    div.ant-modal-root .ant-btn-link,
    div.ant-drawer-open .ant-btn-link,
    div.ReactModalPortal .ant-btn-link {
        font-weight: 700;
        text-transform: uppercase;
    }
    #root .ant-layout .ant-btn:focus,
    div.ant-modal-root .ant-btn:focus,
    div.ant-drawer-open .ant-btn:focus,
    div.ReactModalPortal .ant-btn:focus {

    }
    #root .ant-layout .ant-btn-primary:hover,
    div.ant-modal-root .ant-btn-primary:hover,
    div.ant-drawer-open .ant-btn-primary:hover,
    div.ReactModalPortal .ant-btn-primary:hover,
    #root .ant-layout .ant-btn-primary:focus,
    div.ant-modal-root .ant-btn-primary:focus,
    div.ant-drawer-open .ant-btn-primary:focus,
    div.ReactModalPortal .ant-btn-primary:focus {
        color: #E3F7EE;
        background-color: #00A96C;
        border-color: #00A96C;
        font-weight: 700;
        text-transform: uppercase;
        .svg-inline--fa {
            color: #525252;
            colorX: #E3F7EE;
        }
    }
    #root .ant-layout .ant-btn-primary.ant-btn-dangerous:hover,
    div.ant-modal-root .ant-btn-primary.ant-btn-dangerous:hover,
    div.ant-drawer-open .ant-btn-primary.ant-btn-dangerous:hover,
    div.ReactModalPortal .ant-btn-primary.ant-btn-dangerous:hover,
    #root .ant-layout .ant-btn-primary.ant-btn-dangerous:focus,
    div.ant-modal-root .ant-btn-primary.ant-btn-dangerous:focus,
    div.ant-drawer-open .ant-btn-primary.ant-btn-dangerous:focus,
    div.ReactModalPortal .ant-btn-primary.ant-btn-dangerous:focus,
    #root .ant-layout .ant-btn.ant-btn-dangerous:hover,
    div.ant-modal-root .ant-btn.ant-btn-dangerous:hover,
    div.ant-drawer-open .ant-btn.ant-btn-dangerous:hover,
    div.ReactModalPortal .ant-btn.ant-btn-dangerous:hover,
    #root .ant-layout .ant-btn.ant-btn-dangerous:focus,
    div.ant-modal-root .ant-btn.ant-btn-dangerous:focus,
    div.ant-drawer-open .ant-btn.ant-btn-dangerous:focus,
    div.ReactModalPortal .ant-btn.ant-btn-dangerous:focus,
    #root .ant-layout .ant-btn-danger:hover,
    div.ant-modal-root .ant-btn-danger:hover,
    div.ant-drawer-open .ant-btn-danger:hover,
    div.ReactModalPortal .ant-btn-danger:hover,
    #root .ant-layout .ant-btn-danger:focus,
    div.ant-modal-root .ant-btn-danger:focus,
    div.ant-drawer-open .ant-btn-danger:focus,
    div.ReactModalPortal .ant-btn-danger:focus {
        background-color: white;
        color: $antBtnDangerEthimo;
        border-color: $antBtnDangerEthimo;
        font-weight: 700;
        text-transform: uppercase;
        .svg-inline--fa {
            color: $antBtnDangerEthimo;
        }
    }
    #root .ant-layout .ant-btn-default:hover,
    div.ant-modal-root .ant-btn-default:hover,
    div.ant-drawer-open .ant-btn-default:hover,
    div.ReactModalPortal .ant-btn-default:hover,
    #root .ant-layout .ant-btn-default:focus,
    div.ant-modal-root .ant-btn-default:focus,
    div.ant-drawer-open .ant-btn-default:focus,
    div.ReactModalPortal .ant-btn-default:focus {
        color: #00C48C;
        background-color: transparent;
        border: solid 1px #00C48C;
        font-weight: 700;
        text-transform: uppercase;
        .svg-inline--fa {
            color: #525252;
            colorX: #00C48C;
        }
    }
    #root .ant-layout .ant-btn-link:hover,
    div.ant-modal-root .ant-btn-link:hover,
    div.ant-drawer-open .ant-btn-link:hover,
    div.ReactModalPortal .ant-btn-link:hover,
    #root .ant-layout .ant-btn-link:focus,
    div.ant-modal-root .ant-btn-link:focus,
    div.ant-drawer-open .ant-btn-link:focus,
    div.ReactModalPortal .ant-btn-link:focus {
        color: #392E95;
        border: none;
        font-weight: 700;
        text-transform: uppercase;
        .svg-inline--fa {
            color: #392E95;
        }
    }

    .ant-btn.ant-input-search-button,
    .ant-btn.ant-input-search-button:focus,
    #root .ant-btn.ant-btn-text,
    #root .ant-btn.ant-btn-text:focus,
    .ant-btn.ant-btn-text,
    .ant-btn.ant-btn-text:focus {
        color: #00C48C;
        font-weight: 700;
        text-transform: uppercase;
        border-color: transparent;
        .svg-inline--fa {
            color: #525252;
            .fa-primary {
                color: #525252;
                opacity: 1.0;
            }
            .fa-secondary {
                color: #525252;
                opacity: 1.0;
            }
        }
        .svg-inline--fa.fa-iy2b-duotone-trash,
        .svg-inline--fa.fa-trash-can,
        .svg-inline--fa.fa-ban {
            color: $antBtnDangerEthimo;
            .fa-primary {
                color: $antBtnDangerEthimo;
                opacity: 1.0;
            }
            .fa-secondary {
                color: #C1657F;
                opacity: 1.0;
            }
        }
    }
    .ant-tree {
        .ant-tree-iconEle {
            font-size: 18px;
            .svg-inline--fa {
                .fa-primary {
                    color: #525252;
                    opacity: 1.0;
                }
                .fa-secondary {
                    color: #525252;
                    opacity: 1.0;
                }
            }
        }
    }

    #root .ant-btn.ant-btn-text:hover,
    .ant-btn.ant-btn-text:hover {
        color: #00C48C;
        border-color: transparent;
        .svg-inline--fa {
            color: #525252;
            .fa-secondary {
                color: #525252;
            }
        }
    }

    #root .ant-table-cell .resultado-action-view,
    .ant-table-cell .resultado-action-view {
        .ant-btn {
            font-size: 18px;
        }
    }

    li.ant-menu-item {
        button.ant-btn-text.botaoFavorito {
            .svg-inline--fa.fa-star, .svg-inline--fa.fa-star:focus {
                color: goldenrod;
            }
            .svg-inline--fa.fa-star:hover {
                color: goldenrod;
            }
        }
        button.ant-btn-text.botaoFavorito:hover {
            color: goldenrod;
            .svg-inline--fa.fa-star:hover {
                color: goldenrod;
            }
        }
    }

    div.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.popup-ws-menu-lateral {
        ul.ant-menu.ant-menu-sub {
            background-color: #E3F7EE;
            div.ant-menu-submenu-title:hover {
                border-left: solid 2px #00B978;
                border-radius: 0px;
                .ant-menu-title-content {
                    font-weight: 700;
                    color: #00B978;
                }
            }
            li.ant-menu-item:hover {
                .ant-menu-title-content {
                    font-weight: 700;
                    color: #00B978;
                }
            }
        }
        .ant-menu-title-content:hover {
            font-weight: 700;
            color: #00B978;
        }
        .ant-menu-item.ant-menu-item-disabled.ant-menu-item-only-child {
            .ant-menu-title-content {
                font-weight: 700;
            }
            .ant-menu-title-content:hover {
                color: #141414;
            }
        }
        button.botaoRemExecAutom,
        button.botaoPickExecAutom {
            color: $antBtnPrimaryEthimo;
            .svg-inline--fa {
                color: $antBtnPrimaryEthimo;
            }
        }
    }

    .ant-layout, .ant-modal-root {
        .ant-pro-table.iy2b-table {
            border: solid 1px #F8F8F8;
            .ant-pro-table-list-toolbar {
                background-color: #F8F8F8;
            }
            .ant-table-wrapper {
                .ant-table.ant-table-bordered {
                    box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;
                }
            }
        }
    }
    .ReactModal__Content {
        .ant-pro-table.iy2b-table {
            border: none;
            .ant-pro-table-list-toolbar {
                background-color: #FFF;
            }
            .ant-table-wrapper {
                .ant-table.ant-table-bordered {
                    box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;
                }
            }
        }
    }
    .ant-tabs {
        .ant-tabs-nav {
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    background-color: #EFEFEF;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 20px;
                    color: #ADADAD;
                    font-weight: 400;
                }
                .ant-tabs-tab:hover {
                    font-weight: 600;
                }
                .ant-tabs-tab.ant-tabs-tab-active {
                    background-color: #D3CAEB;
                    border-bottom: none;
                    .ant-tabs-tab-btn {
                        color: #4B3AA3;
                    }
                }
                .ant-tabs-ink-bar {
                    background: #4B3AA3;
                }
            }
        }
    }
    .ant-btn.ant-btn-default.btn-alternative {
        background-color: #00C48C;
    }
    .ant-btn.ant-btn-default.btn-alternative:hover,
    .ant-btn.ant-btn-default.btn-alternative:focus,
    .ant-btn.ant-btn-default.btn-alternative:active,
    {
        background-color: #00975E !important;
        border-color: #00975E !important;
    }
    .ant-drawer-content.help-drawer,
    .ant-drawer-content.notifications-drawer,
    .ant-drawer-content.iy2b-drawer-editor {
        box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;
        border-left: solid 2px #8DDCBC;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        .ant-drawer-header {
            background-color: #FFF;
            border: none !important;
            padding-bottom: 4px;
            .ant-drawer-header-title {
                button.ant-drawer-close {
                    color: #00B978;
                }
                button.ant-drawer-close:hover {
                    color: #141414;
                }
                .ant-drawer-title {
                    color: #00B978;
                    padding-left: 10px;
                    font-size: 24px;
                    font-weight: 500;
                }
            }
            .ant-drawer-extra {
                .botao-fechar {
                    background-color: #D3CAEB;
                    .svg-inline--fa {
                        color: #F8F8F8;
                    }
                }
            }
        }
        .ant-drawer-body {
            background-color: #fff;
        }
    }

    .ant-drawer-content.iy2b-drawer-editor {
        .ant-drawer-header {
            .ant-drawer-header-title {
                button.ant-drawer-close {
                    display: none;
                }
            }
        }
    }

    .ant-select-item.ant-select-item-option.ant-select-item-option-active {
        font-weight: 500;
    }

    ul.ant-dropdown-menu {
        li.ant-dropdown-menu-item {
            .svg-inline--fa.ant-dropdown-menu-item-icon {
                color: #525252;
                font-size: 18px;
                .fa-primary {
                    color: #525252;
                    opacity: 1.0;
                }
                .fa-secondary {
                    color: #525252;
                    opacity: 1.0;
                }
            }
            .svg-inline--fa.fa-iy2b-duotone-trash.ant-dropdown-menu-item-icon,
            .svg-inline--fa.fa-trash-can.ant-dropdown-menu-item-icon,
            .svg-inline--fa.fa-ban.ant-dropdown-menu-item-icon {
                color: $antBtnDangerEthimo;
                .fa-primary {
                    color: $antBtnDangerEthimo;
                    opacity: 1.0;
                }
                .fa-secondary {
                    color: #C1657F;
                    opacity: 1.0;
                }
            }
            .ant-dropdown-menu-title-content {
                font-weight: 400;
            }
        }
        li.ant-dropdown-menu-item:hover {
            .ant-dropdown-menu-title-content {
                font-weight: 500;
            }
        }
    }
    div.ant-pro-table.iy2b-table {
        span.iy2b-table-search-box {
            button.ant-btn.ant-input-search-button.ant-btn-primary {
                color: #d9d9d9 !important;
                background-color: white !important;
                border: solid 1px #d9d9d9 !important;
                border-radius: 6px !important;
                border-left: none !important;
                border-top-left-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
            }
        }
    }
    #root {
        div.containerFiltro.exibe {
            .ant-card-body {
                form.ant-form {
                    background-color: #EEE9F7;
                }
            }
        }
        button.ant-btn-text.botaoAcoesDisponiveis,
        button.ant-btn-text.botaoAcoesDisponiveis:focus {
            background-color: #00B978;
            color: #E3F7EE;
            .svg-inline--fa {
                color: #E3F7EE;
            }
        }
        button.ant-btn-text.botaoAcoesDisponiveis:hover {
            border: solid 1px #00B978;
            background-color: #E3F7EE;
            color: #00B978;
            .svg-inline--fa {
                color: #00B978;
            }
        }
        .formFiltroContainer {
            div.containerFiltro.exibe {
                background-color: #EEE9F7;
                border: solid 1px #ccc;
                .ant-card {
                    background-color: #EEE9F7;
                }
            }
            button.ant-btn-text.botaoFiltrar,
            button.ant-btn-text.botaoFiltrar:focus {
                background-color: #8267C6;
                color: #EEE9F7;
                .svg-inline--fa {
                    color: #EEE9F7;
                }
            }
            button.ant-btn-text.botaoFiltrar:hover {
                border: solid 1px #8267C6;
                background-color: #D3CAEB;
                color: #8267C6;
                .svg-inline--fa {
                    color: #8267C6;
                }
            }
        }
        .workspace.v2 {
            #empresaDropdown {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .containerBtnEmpresa {
                    height: 46px;
                    padding: 0px;
                    border: none;
                    #btnEmpresa {
                        margin: 0px 4px 0px 4px;
                        a {
                            width: 310px;
                            padding: 0px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            div.conteudoBotaoEmpresa {
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;
                                flex-direction: column;
                                line-height: 22px;
                                div.noEmpresa {
                                    display: block;
                                }
                                div.noPlano {
                                    display: block;
                                    font-weight: 400;
                                    color: #8D8D8D;
                                    height: 22px;
                                }
                            }
                            .containerCaret {
                                width: unset;
                            }
                        }
                    }
                }
            }
            #empresaDropdown.open {
                .containerBtnEmpresa {
                    #btnEmpresa {
                        border: none;
                    }
                }
            }
        }
        #mainWorkspace.workspace.v2.debug-habilitado {
            #headerWS.ant-layout-header {
                border-radius: 8px;
                background-color: #E00000;
                #btnEmpresa {
                    a {
                        border-radius: 8px;
                    }
                }
            }
        }
        #mainWorkspace.workspace {
            #containerMenuLateral {
                #menuLateral {
                    .ant-layout-sider-children {
                        background-color: #F8F8F8;
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 30px;
                        border-top-left-radius: 0px;
                        border-top-right-radius: 30px;
                        div.logo-container {
                            background-color: white;
                            border-top-right-radius: 30px;
                            .logo-radius {
                                height: 56px;
                                display: flex;
                                border-radius: 40px;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                div.ant-image {
                                    img.logo {
                                    }
                                }
                            }
                            .logo-radius.circulo {
                                background-color: #00C48C;
                                width: 56px;
                            }
                            .logo-radius.extendido {
                                background-color: #FFFFFF;
                                width: 123px;
                            }
                        }
                        ul.ant-menu.ws-menu-lateral {
                            background-color: white;
                            .svg-inline--fa.ant-menu-item-icon {
                                color: #8D8D8D;
                            }
                        }
                        .boxBotaoLogout {
                            background-color: white;
                            border-bottom-right-radius: 30px;
                            button.botaoLogout {
                                background-color: #EEE9F7;
                                .svg-inline--fa {
                                    color: #5640AC;
                                }
                            }
                        }
                    }
                }
                .boxBotaoAbreFecha {
                    .gap-header {
                        background-color: #F8F8F8;
                    }
                    .containerBotaoAbreFecha {
                        border: none;
                        background-color: #F8F8F8;
                        width: 24px;
                        button.botaoFechar,
                        button.botaoAbrir {
                            border: none;
                            width: 18px;
                            height: 64px;
                            background-color: #EEE9F7;
                            .svg-inline--fa {
                                color: #5640AC;
                            }
                        }
                    }
                }
            }
            .ant-layout, .ant-modal-root {
                #headerWS.ant-layout-header {
                    background-color: #F8F8F8;
                }
            }
            #containerContent {
                background-color: #F8F8F8;
                div.ant-tabs.ant-tabs-card {
                    background-color: #F8F8F8;
                }
                .ant-tabs-nav {
                    .ant-tabs-nav-wrap {
                        background-color: #F8F8F8;
                        border-bottom: solid 1px #d2d2d2;
                    }
                }
            }
            #containerContent .transacaoContent {
                background-color: #F8F8F8;
                padding-left: 2px;
                padding-right: 2px;
                .ant-layout {
                    .ant-layout-content {
                        background-color: #F8F8F8;
                        .ant-form {
                            background-color: #F8F8F8;
                        }
                    }
                }
            }
            #empresaDropdown {
                #btnEmpresa {
                    a {
                        background-color: #F8F8F8;
                        color: #343434;
                        font-weight: 500;
                        border: none;
                        @include md {
                            background-color: #F8F8F8;
                        }
                        span.containerCaret {
                            .svg-inline--fa {
                                color: #343434;
                            }
                        }
                    }
                    a:hover, a:focus {
                        color: #BABABA;
                        border-radius: 8px;
                        div.noPlano {
                            color: #BABABA;
                        }
                        span.containerCaret {
                            .svg-inline--fa {
                                color: #BABABA;
                            }
                        }
                    }
                }
            }
            #empresaDropdown.open {
                background-color: #F8F8F8;
                .containerBtnEmpresa {
                    border-radius: 8px;
                    background-color: #EFEFEF;
                    z-index: 2049;
                    #btnEmpresa {
                        z-index: 2050;
                        a {
                            background-color: #EFEFEF;
                            color: #BABABA;
                            span.containerCaret {
                                .svg-inline--fa {
                                    color: #BABABA;
                                }
                            }
                        }
                    }
                }
            }
            #empresaDropdown.close {
                .containerBtnEmpresa {
                    background: transparent;
                    z-index: 10;
                    #btnEmpresa {
                        z-index: 10;
                        font-family: "Space Grotesk", sans-serif;
                    }
                }
            }
        }
        #wizardContratar {
            height: 100vh;
            .ant-layout-header {
                background-color: #F8F8F8;
                label {
                    color: #000000E0;
                }
            }
            .ant-layout-content {
                background-color: #F8F8F8;
                .ant-pro-steps-form-step {
                    border-radius: 12px;
                    /*box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;*/
                }
                form {
                    background-color: #F8F8F8;
                    .box-login-provedores {
                        .ant-btn-default.google {
                            background-color: white;
                            border-color: #656565;
                        }
                        .ant-btn-default.apple {
                            background-color: black;
                            border-color: black;
                        }
                        .ant-btn-default.facebook {
                            background-color: #3b5998;
                            border-color: #3b5998;
                        }
                    }
                }
                #stepPlano {
                    .grid-planos {
                        border-radius: 12px;
                        .ant-tabs-nav-wrap {
                            border-top-left-radius: 12px;
                            border-bottom-left-radius: 12px;
                        }
                    }
                    .box-preco {
                        .toolbar {
                            button.ant-btn {
                                font-size: 18px;
                            }
                        }
                    }

                }
                .ant-pro-steps-form .ant-pro-steps-form-container .ant-space-item button:focus,
                .ant-pro-steps-form .ant-pro-steps-form-container .ant-space-item button {
                    background-color: #8267C6;
                    border-color: #8267C6;
                    color: #F8F8F8;
                }
                .ant-pro-steps-form .ant-pro-steps-form-container .ant-space-item button:hover {
                    background-color: #D3CAEB;
                    border-color: #8267C6;
                    color: #8267C6;
                }
                .ant-pro-steps-form {
                    .ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-icon {
                        background-color: #8267C6;
                        border-color: #8267C6;
                    }
                    .ant-steps-item.ant-steps-item-finish {
                        .ant-steps-item-icon {
                            .ant-steps-icon {
                                color: #38c593;
                            }
                            border-color: #38c593;
                        }
                        .ant-steps-item-content {
                            .ant-steps-item-title::after {
                                background-color: #38c593;
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-layout, .ant-modal-root, .ReactModal__Content {
        .ant-pro-table.iy2b-table {
            .ant-table-wrapper {
                thead {
                    tr {
                        th {
                            background-color: #6C4EBC;
                        }
                    }
                }
                tr {
                    td {
                        background-color: white;
                        .ant-btn.ant-btn-text {
                            color: #6C4EBC;
                            .svg-inline--fa {
                                color: #525252;
                                .fa-primary {
                                    color: #525252;
                                    opacity: 1.0;
                                }
                                .fa-secondary {
                                    color: #525252;
                                    opacity: 1.0;
                                }
                            }
                            .svg-inline--fa.fa-iy2b-duotone-trash,
                            .svg-inline--fa.fa-trash-can,
                            .svg-inline--fa.fa-ban {
                                color: $antBtnDangerEthimo;
                                .fa-primary {
                                    color: $antBtnDangerEthimo;
                                    opacity: 1.0;
                                }
                                .fa-secondary {
                                    color: #C1657F;
                                    opacity: 1.0;
                                }
                            }
                        }
                        .ant-btn.ant-btn-text:focus, .ant-btn.ant-btn-text:hover {
                            border: solid 2px #00B978 !important;
                            border-radius: 8px;
                            color: #6C4EBC;
                            .svg-inline--fa {
                                color: #6C4EBC !important;
                            }
                        }
                    }
                }
                tr:nth-child(2n) td {
                    background-color: #F8F8F8;
                }
                tr.ant-table-row:hover > td,
                tr > td.ant-table-cell-row-hover {
                    background: #E3F7EE;
                    .ant-checkbox-wrapper .ant-checkbox-inner,
                    .ant-checkbox .ant-checkbox-inner {
                        border: solid 2px #00B978;
                        color: #6C4EBC;
                        .svg-inline--fa {
                            color: #6C4EBC;
                        }
                    }
                    button.ant-btn {
                        border: solid 2px #00B978;
                        border-radius: 8px;
                        color: #6C4EBC;
                        .svg-inline--fa {
                            color: #6C4EBC;
                        }
                    }
                }
                tr > td.ant-table-cell-row-hover:first-child {
                    border-left: solid 2px #00B978;
                }
                tr > td.ant-table-cell-row-hover:last-child {
                    border-right: solid 2px #00B978;
                }
            }
        }
    }
    #checklistProcessoDropdown {
        .containerBtnTeacher {
            border: solid 1px #00C48C;
        }
        #btnTeacher {
            background-color: #00C48C;
        }
    }
    #botaoWizard {
        .containerBtnWizard {
            border: solid 1px #00C48C;
        }
        #btnWizard {
            background-color: #00C48C;
        }
    }
    #empresaDropdown {
        .containerBtnEmpresa {
            border: solid 1px #00C48C;
        }
    }
    .ant-dropdown.ethimo.dropdown-acoes-disponiveis,
    .ant-dropdown.ethimo.dropdown-sort-data-cards,
    .ant-dropdown.ethimo.dropdown-acoes-disponiveis-cards,
    .ant-dropdown.ethimo.dropdown-acoes-disponiveis-rnd {
        box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;
        border: solid 2px #8DDCBC;
        border-radius: 8px;
        background-color: #FFF;
        .ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ethimo {
            padding-bottom: 16px;
            padding-top: 16px;
            .svg-inline--fa.ant-dropdown-menu-item-icon {
                font-weight: 500;
                font-size: 20px;
            }
        }
    }
}

body.iy2b-app-body.ethimo.popUpWizNovaEmpresa,
body.iy2b-app-body.ethimo.popUpWizNovoContrato {
    .box-body {
        background-color: #F8F8F8;
        .ant-pro-steps-form-step {
            border-radius: 12px;
            /*box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;*/
        }
        form {
            .box-login-provedores {
                .ant-btn-default.google {
                    background-color: white;
                    border-color: #656565;
                }
                .ant-btn-default.apple {
                    background-color: black;
                    border-color: black;
                }
                .ant-btn-default.facebook {
                    background-color: #3b5998;
                    border-color: #3b5998;
                }
            }
        }
        #stepPlano {
            .grid-planos {
                border-radius: 12px;
                .ant-tabs-nav-wrap {
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }
            }
            .box-preco {
                .toolbar {
                    button.ant-btn {
                        font-size: 18px;
                    }
                }
            }

        }
        #stepCertDig {
            height: 100%;
            .containerStepCertDig {
                max-width: 720px;
                height: 100%;
                border-radius: 8px;
            }
            .ant-upload-drag {
                border: solid 1px #CCC;
                padding: 10px;
                height: 170px;
            }
            .rowListaReqs {
                margin: 8px 4px 16px 4px;
                .naoQueroCertificado {
                    white-space: break-spaces;
                    height: 100%;
                }
            }
        }
        .ant-pro-steps-form .ant-pro-steps-form-container .ant-space-item button:focus,
        .ant-pro-steps-form .ant-pro-steps-form-container .ant-space-item button {
            background-color: #8267C6;
            border-color: #8267C6;
            color: #F8F8F8;
        }
        .ant-pro-steps-form .ant-pro-steps-form-container .ant-space-item button:hover {
            background-color: #D3CAEB;
            border-color: #8267C6;
            color: #8267C6;
        }
        .ant-pro-steps-form {
            .ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-icon {
                background-color: #8267C6;
                border-color: #8267C6;
            }
            .ant-steps-item.ant-steps-item-finish {
                .ant-steps-item-icon {
                    .ant-steps-icon {
                        color: #38c593;
                    }
                    border-color: #38c593;
                }
                .ant-steps-item-content {
                    .ant-steps-item-title::after {
                        background-color: #38c593;
                    }
                }
            }
        }
    }
}

body.ethimo.popUpWizAtualizarCertDig,
body.ethimo.popUpWizNovoUsuario,
body.ethimo.popUpWizNovaEmpresa,
body.ethimo.popUpWizNovoContrato {
    .modalContent {
        background-color: #FFF;
    }
}

body.ethimo.popUpWizAtualizarCertDig,
body.ethimo.popUpWizNovoUsuario,
body.ethimo.popUpWizNovaEmpresa,
body.ethimo.popUpWizNovoContrato,
body.ethimo.iy2b-react-modal {
    .modalContent {
        box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;
        border: solid 2px #8DDCBC;
        border-radius: 16px;
        .modal-header {
            background-color: #FFFFFF;
            color: #00B978;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .title {
                padding-left: 25px;
                font-size: 24px;
                font-weight: 500;
            }
        }
        .box-body {
            background-color: #FFFFFF;
        }
        .box-actions {
            background-color: #FFFFFF;
        }
        .modal-footer {
            background-color: #FFFFFF;
        }
    }
}
body.ethimo.empresaDropdownModal {
    .modalContent {
        margin-top: 8px;
        border-radius: 8px;
        .header {
            xbackground-color: #8DDCBC;
            background-color: #6C4EBC;
            color: #FFF;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        .scroller {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            background-color: white;
            padding-left: 8px;
            padding-right: 8px;
            .ant-list {
                .ant-list-item {
                    border-radius: 8px;
                    a.opcao {
                        font-weight: 400;
                        color: #111;
                    }
                }
                .ant-list-item.active {
                    background: #00C48C;
                    a.opcao {
                        font-weight: 400;
                        color: #E3F7EE;
                    }
                }
                .ant-list-item:hover {
                    background: #E3F7EE;
                    a.opcao {
                        font-weight: 500;
                        color: #111;
                    }
                }
            }
        }
    }
}
body.ethimo.usuarioDropdownModal {
    #usuarioDropdown.open {
        a {
            background: #EEE9F7;
        }
    }
    .modalContent {
        #noPessoaUsr {
            background-color: #EEE9F7;
        }
    }
}
body.ethimo.botaoWizardModal {
    .modalOverlay {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .modalContent {
        .containerDropdownBtnWizard {
            background-color: #00C48C;
        }
    }
}
body.ethimo.checklistProcessoModal {
    .modalContent {
        .containerDropdownCkL {
            background-color: #00C48C;
        }
    }
}
body.ethimo.menuSuspensoModal {
    .modalOverlay {
        background-color: #00C48C;
    }
    .modalContent {
        .ant-layout-sider-children {
            background: #00975E;
            #listaLateralScroller {
                .ant-list {
                    .ant-list-item.active {
                        background: #00653B;
                    }
                    .ant-list-item:hover {
                        background-color: #00B978;
                    }
                }
            }
        }
        .ant-layout-header {
            background: #00653B;
        }
        .ant-layout-content {
            background: #00975E;
            #contentScroller {
                .ant-list-item {
                    a.opcao:hover {
                        background-color: #00B978;
                    }
                }
            }
        }
    }
}
body.ethimo.usuarioDropdownModal {
    .modalContent {
        .boxLabel {
            background-color: #EEE9F7;
            #noPessoaUsr {
                color: #141414;
            }
        }
        .scroller {
            .container {
                background-color: #EEE9F7;
                .boxBotao {
                    .botao {
                        color: #9982D1;
                        border: solid 1px #9982D1;
                    }
                    .botao:hover {
                        color: #141414;
                        border: solid 1px #141414;
                    }
                }
            }
        }
    }
}
body.ethimo.iy2b-app-body {
    div.ant-dropdown.usuarioDropdownModal {
        background-color: transparent;
        box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;
        border-radius: 16px;
        .scroller {
            margin-top: 16px;
            background-color: white;
            box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;
            border-radius: 16px;
            .container {
                background-color: white;
                box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;
                border-radius: 16px;
                padding: 16px;
                .boxBotao {
                    height: 38px;
                    .botao {
                        align-items: center;
                        justify-content: center;
                        margin: 0px;
                        padding: 0px;
                        height: 38px;
                        width: 124px;
                        .icone {
                            color: #00B978;
                        }
                        .label {
                            color: #525252;
                            font-weight: 400;
                            font-size: 14px;
                        }
                        border: none;
                    }
                    .botao:hover {
                        .label {
                            color: #525252;
                            font-weight: 600;
                            font-size: 14px;
                        }
                        border: none;
                    }
                }
                #rodape {
                    .boxBotao {
                        .botao {
                            .icone {
                                padding: 6px 10px;
                                background-color: #EEE9F7;
                                color: #5640AC;
                                border-radius: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}


body.ethimo.iy2b-app-body.formFiltroPopUpModal {

    .filtroElements {
        border: solid 2px #8DDCBC;
        border-radius: 16px;
    }
}

body.ethimo.iy2b-app-body {
    .ant-select-dropdown.dropdownSearchBotaoPesquisaWS {
        .ant-select-item.ant-select-item-option {
            font-weight: 400;
        }
        .ant-select-item.ant-select-item-option.ant-select-item-option-active {
            font-weight: 500;
            background-color: #E3F7EE;
        }
    }
}
