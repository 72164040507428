body.iy2b-app-body #root,
body.iy2b-app-body #root #wizardContratar {
    button.ant-btn.google {
        color: #757575 !important;
        background-color: #E4E4E4 !important;
        text-transform: unset !important;
        .ant-btn-icon {
            font-size: 16px;
            .svg-inline--fa {
                color: #757575 !important;
            }
        }
    }
}

.tem-desvinc .google {
    width: calc(100% - 36px);
    margin-right: 2px;
}

#root .ant-layout button.ant-btn.google:focus,
#root .ant-layout button.ant-btn.google:active,
#root .ant-layout button.ant-btn.google:hover, {
    background-color: white !important;
    color: #757575 !important;
    border-color: #757575 !important;
    .ant-btn-icon {
        .svg-inline--fa {
            color: #757575 !important;
        }
    }
}

#root .ant-layout button.ant-btn.google[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}
