@import "../../../../framework/assets/css/variaveis";
@import "../../../assets/css/breakpoints";

.box-folded-form-field {

    .ant-collapse:hover {
        background-color: #EEE9F7;
    }

    .ant-collapse {

        background-color: #EFEFEF;

        .ant-collapse-item.ant-collapse-item-active {
            .ant-collapse-header {
                background-color: #6C4EBC;
                border-radius: 8px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                .ant-collapse-expand-icon {
                    svg.svg-inline--fa {
                        color: #FFF;
                    }
                }
                .ant-collapse-header-text {
                    color: #FFF;
                }
                div.ant-collapse-extra {
                    .svg-inline--fa {
                        color: #FFF;
                    }
                }
            }
        }

        .ant-collapse-header:hover {
            background-color: #EEE9F7;
        }

        .ant-collapse-header {
            background-color: #EFEFEF;
            .ant-collapse-expand-icon {
                svg.svg-inline--fa {
                    font-weight: 500;
                    color: #525252;
                }
            }
            .ant-collapse-header-text {
                font-weight: 500;
                color: #525252;
            }
            div.ant-collapse-extra {
                order: -1;
                padding-right: 8px;
                height: 22px;
                display: flex;
                align-items: center;
                .svg-inline--fa {
                    font-size: 18px;
                    font-weight: 500;
                    color: #525252;
                }
            }
        }

        .ant-collapse-content {
            background-color: #FFF;
            .ant-collapse-content-box {
                .ant-pro-table.iy2b-table {
                    border: none;
                    .ant-pro-table-list-toolbar {
                        background-color: transparent;
                    }
                }
            }
        }

    }

}

.box-folded-form-field.hidden {
    display: none;
}

.box-folded-form-field.gap-before {

    .ant-collapse {
        margin-top: 15px;
    }

}

.box-folded-form-field.gap-after {

    .ant-collapse {
        margin-bottom: 10px;
    }

}

.box-folded-form-field.full-width {

    .ant-collapse {

        .ant-collapse-header {
        }

        .ant-collapse-content {

            .ant-pro-form-group-container {
                width: 100%;
            }

            .ant-pro-form-group-container > div.ant-space-item {
                width: 100%;
            }

        }

    }

}
