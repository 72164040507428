
.ant-drawer-content.help-drawer {
    .ant-drawer-header {
        background-color: #000;
        .ant-drawer-header-title {
            button.ant-drawer-close {
                color: #fff;
            }
            .ant-drawer-title {
                color: #fff;
            }
        }
    }
}


.slideWSContent-enter {
    left: 0px;
    animation-duration: .85s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-play-state: paused;
}

.slideWSContent-leave {
    animation-duration: .95s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-play-state: paused;
}

.slideWSContent-enter.slideWSContent-enter-active {
    animation-name: slideWSContentIn;
    animation-play-state: running;
}

.slideWSContent-leave.slideWSContent-leave-active {
    animation-name: slideWSContentOut;
    animation-play-state: running;
}

@-webkit-keyframes slideWSContentIn {
    0% {
        left: 0px;
        position: absolute;
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        left: 500px;
        position: relative;
        opacity: 1;
    }
}

@keyframes slideWSContentIn {
    0% {
        left: 0px;
        position: absolute;
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        left: 500px;
        position: relative;
        opacity: 1;
    }
}

@-webkit-keyframes slideWSContentOut {
    0% {
        left: 500px;
        position: absolute;
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        left: 0px;
        position: relative;
        opacity: 1;
    }
}

@keyframes slideWSContentOut {
    0% {
        left: 500px;
        position: absolute;
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        left: 0px;
        position: relative;
        opacity: 1;
    }
}

#mainWorkspace {
    #bodyLayoutWS {
        margin-right: 10px;
    }
}

.workspace {

    height: 100vh;

    .wrkspLoadingPosition {

        width: 100%;
        position: relative;

        .wrkspBackdropLoading {
            z-index: 9999;
            background-color: rgba(255, 255, 255, 0.65);
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }

    }

}

