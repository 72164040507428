.ReactModalPortal .modalContent {
    .root-container-iy2b-card {
        .ant-card {
            background-color: #EFEFEF;
            .ant-card-actions {
                background-color: #EFEFEF !important;
            }
        }
    }
}

.ReactModalPortal .modalContent,
#root #mainWorkspace {
    .root-container-iy2b-cards {
        margin-top: 8px;
        div.container-toolbar {
            position: relative;
            height: 32px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div.search {
                .ant-input-group-wrapper.ant-input-search.ethimo {
                    width: 275px;
                    .ant-input-group-addon {
                        button {
                            border: solid 1px #d9d9d9;
                            color: #d9d9d9;
                            .svg-inline--fa {
                                color: #d9d9d9;
                            }
                        }
                    }
                }
            }
            div.actions {
                display: flex;
                width: 210px;
                justify-content: flex-end;
                .box-btn-acoes-disponiveis.inside-iy2b-cards {
                    text-align: right;
                }
                button.botao-sort {
                    margin-right: 8px;
                    background-color: #00B978;
                    .svg-inline--fa {
                        color: #E3F7EE;
                    }
                }
            }
        }

        div.container-toolbar-actions {
            position: relative;
            height: 32px;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
            .ant-input-group-wrapper.ant-input-search.ethimo {
                width: 200px;
            }
        }

        div.scroller {
            overflow: auto;
            padding: 0px;
            .ant-list-item {
                border: none;
                .root-container-iy2b-card {
                    .ant-card {
                        box-shadow: 0px 6.425755977630615px 18.20630645751953px -5.354796886444092px #3A4DE926;
                        .ant-card-head {
                            .ant-card-head-wrapper {
                                .ant-card-head-title {
                                    overflow: visible;
                                    white-space: normal;
                                    text-overflow: unset;
                                    text-align: center;
                                }
                            }
                        }
                        .ant-card-body {
                            padding: 8px;
                            .ant-descriptions {
                                .ant-descriptions-view {
                                    .ant-descriptions-item {
                                        padding-bottom: 4px;
                                    }
                                }
                            }
                        }
                        .ant-card-actions {
                            li {
                                margin: 6px 0px 6px 0px;
                            }
                        }
                    }
                }
                .root-container-iy2b-card.pointer:hover {
                    cursor: pointer;
                }
                .checked {
                    border: solid 1px #9747FF;
                    border-radius: 8px;
                    box-shadow: 0px 6.425755977630615px 18.20630645751953px -5.354796886444092px #9747FF26;
                }
            }
        }

        div.footer {
            height: 24px;
            margin-top: 8px;
            text-align: right;
        }

    }
}
