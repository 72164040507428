.dropdownSearchMenuSuspenso {
    z-index: 2050;

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: #e0e1a8;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: #CCC;
    }

}

.dropdownSearchMenuSuspenso.ant-select-dropdown-hidden {
    display: inline-block;
}

#menuSuspenso.open {
    background: #7c7c7c;
    #btnMenu {
        z-index: 2050;
        color: antiquewhite;
    }
}

#menuSuspenso.close {

    #btnMenu {
        z-index: 10;
    }

}

#menuSuspenso {
    height: 64px;
    #btnMenu {
        display: block;
        text-align: center;
        height: 100%;
        color: white;
    }
    #btnMenu.ant-btn-icon-only > * {
        font-size: 26px;
    }
    #btnMenu:hover, #btnMenu:focus {
        border: none;
    }
}

body.menuSuspensoModal {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgb(0, 0, 0);
        z-index: 2000;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 64px 0px 0px 0px;
        z-index: 2001;

        .ant-list-item {
            padding: 6px 0px;
            border-bottom: none;
            a.opcao {
                width: 100%;
                padding: 0px 5px 0px 10px;
            }
        }

        .ant-layout-sider-children {
            background: rgb(60,60,60);
            color: white;
            padding: 8px;
            border-right: solid 1px grey;

            #acMenuSuspenso {

            }
            #acMenuSuspenso:hover, #acMenuSuspenso:focus {
                border: none;
            }

            .versao {
                position: fixed;
                bottom: 5px;
                font-size: 12px;
            }

            #listaLateralScroller {
                margin-top: 10px;
                width: 257px;
                overflow-x: auto;

                .ant-list {

                    .ant-list-item {
                        a.opcao {
                            color: white;
                            font-size: 20px;
                        }
                    }

                    .ant-list-item.active {
                        background: rgb(45,45,45);
                        border-left: solid 3px antiquewhite;
                        a.opcao {
                            color: silver;
                        }
                    }

                    .ant-list-item:hover {
                        background-color: rgb(90,90,90);
                    }

                }

            }

        }

        .ant-layout-header {
            background: rgb(45,45,45);
            color: silver;
            border-bottom: solid 1px grey;
            font-size: 26px;
            padding: 0px 10px;
            span {
                color: antiquewhite;
                margin-right: 10px;
            }
        }

        .ant-layout-content {
            background: rgb(45,45,45);
            color: white;
            padding: 8px;

            #contentScroller {
                overflow-x: auto;

                .colPrincipal {
                }

                .colSubMenu {

                    padding-left: 5px;

                    .subMenuHeader {
                        font-size: 18px;
                        color: silver;
                    }

                }

                .contentFavoritas {

                    .subMenuHeader {
                        font-size: 18px;
                        color: silver;
                    }

                }

                .ant-list-item {
                    a.icone {
                        color: dimgrey;
                        padding: 0px 0px 0px 5px;
                    }
                    a.icone.favoritado {
                        color: goldenrod;
                    }
                    a.icone:hover {
                        color: goldenrod;
                    }
                    a.opcao {
                        color: white;
                        font-size: 16px;
                    }
                    a.opcao:hover {
                        background-color: rgb(0,0,0);
                        color: antiquewhite;
                    }
                }

            }

        }

    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}

