body div.ReactModalPortal div.body-login-com-token {
    span.input-token {
        input {
            font-size: 37px;
        }
    }
    button.ant-btn-default.btn-validar-token-login {
        background-color: #E3F7EE;
        border-color: #00A96C;
        color: #00A96C;
        font-weight: 700;
        text-transform: uppercase;
    }
    button.ant-btn-default.btn-validar-token-login:hover,
    button.ant-btn-default.btn-validar-token-login:focus,
    button.ant-btn-default.btn-validar-token-login:active {
        color: #E3F7EE;
        background-color: #00A96C;
        border-color: #00A96C;
        font-weight: 700;
        text-transform: uppercase;
    }

}
