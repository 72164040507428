@import "../../../../assets/css/breakpoints";

#containerIconHelp {
    height: 100%;
    padding: 0px 6px 0px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    .svg-inline--fa {
        font-size: 32px;
    }
}

#botaoHelp {

    .containerBtnHelp {
    }

    .containerBtnHelp.aberto {
    }

    #btnHelp {

        height: 44px;
        line-height: 44px;
        position: relative;
        background-color: black;
        margin: 15px 0px 0px 0px;
        border-radius: 0px;
        color: white;

        a {

            color: white;
            display: block;

            .containerIcon {

                font-size: 24px;

                @include md {
                    font-size: 30px;
                }

            }

        }

        a:hover, a:focus {
            color: #999999;
        }

    }

}
