.ant-drawer-content-wrapper {

    #groupRequisitos {

        margin-top: 30px;

        label.ant-checkbox-wrapper {

            margin-top: 10px;

            .ant-checkbox-disabled + span {
                color: red !important;
            }

            .ant-checkbox-checked.ant-checkbox-disabled + span {
                color: darkgreen !important;
            }

        }

    }

}

