@import "variaveis";
@import "breakpoints";

.ant-layout, .ant-modal-root, .ReactModal__Content {

    .ant-pro-table.iy2b-table.not-visible {
        display: none;
    }

    .ant-pro-table.iy2b-table.with-refresh.with-download {

        .ant-pro-table-list-toolbar {

            .ant-pro-table-list-toolbar-container {

                .ant-pro-table-list-toolbar-right {

                    .action-button {
                        border: none;
                    }
                }
            }
        }
    }

    .ant-pro-table.iy2b-table.with-refresh,
    .ant-pro-table.iy2b-table.with-download
    {

        .ant-pro-table-list-toolbar {

            .ant-pro-table-list-toolbar-container {

                .ant-pro-table-list-toolbar-right {

                    .action-button {
                        border: none;
                    }
                }
            }
        }
    }

    .ant-pro-table.iy2b-table {
        .ant-card {
            .ant-card-body {
                padding: 0px;
            }
        }
        .ant-pro-card {
            .ant-pro-card-body {
                padding: 0px;
            }
        }
    }

    .ant-pro-table.iy2b-table {

        .react-resizable {
            position: relative;
            background-clip: padding-box;
        }

        .react-resizable-handle {
            position: absolute;
            right: -5px;
            bottom: 0;
            z-index: 1;
            width: 10px;
            height: 100%;
            cursor: col-resize;
        }

        border: solid 1px #CCC;

        .ant-pro-table-alert {
            display: none;
        }

        padding: 0px !important;

        .ant-pro-table-list-toolbar {

            background-color: #CCC;

            .ant-pro-table-list-toolbar-container {

                padding: 8px 0px !important;
                display: inline-flex;
                width: 100%;

                .ant-pro-table-list-toolbar-left {

                    width: calc(100%);

                    div.ant-space-item:nth-child(2) {
                        width: 100%;
                    }

                    .ant-pro-table-list-toolbar-search {

                        .box-search-title {

                            display: inline-flex;
                            align-items: center;
                            align-content: center;

                            .ant-input-group-wrapper.ant-input-search {
                                width: 250px;
                            }

                            .table-title {
                                width: calc(100% - 250px);
                                padding: 0px 15px;
                                font-size: 18px;
                            }

                            .table-title.not-visible {
                                display: none;
                            }

                        }

                    }

                }

                .ant-pro-table-list-toolbar-right {

                    .ant-pro-table-list-toolbar-setting-items {
                        font-size: 20px !important;
                    }

                }

            }

        }

        .ant-table-wrapper {

            thead {
                tr {
                    th {
                        background-color: #333;
                        color: white;
                        font-size: 12px;

                        @include md {
                            font-size: 14px;
                        }

                    }
                }
            }

            tr:nth-child(2n) td {
                background-color: $primaryColorRowEveryOther;
            }
            tr.ant-table-row-selected:nth-child(2n) td {
                background-color: $tableRowSelect;
            }
            tr.ant-table-row-selected td {
                background-color: $tableRowSelect;
            }

            .ant-pagination.mini {
                margin-right: 5px !important;
            }

        }

    }

}

