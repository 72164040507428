.fadeSiderCheckList-enter {
    left: -500px;
    animation-duration: .65s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-play-state: paused;
}

.fadeSiderCheckList-leave {
    animation-duration: .65s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-play-state: paused;
}

.fadeSiderCheckList-enter.fadeSiderCheckList-enter-active {
    animation-name: fadeSiderCheckListIn;
    animation-play-state: running;
}

.fadeSiderCheckList-leave.fadeSiderCheckList-leave-active {
    animation-name: fadeSiderCheckListOut;
    animation-play-state: running;
}

@-webkit-keyframes fadeSiderCheckListIn {
    0% {
        opacity: 1;
        left: -500px;
    }
    100% {
        opacity: 1;
        left: 0px;
    }
}

@keyframes fadeSiderCheckListIn {
    0% {
        opacity: 1;
        left: -500px;
    }
    100% {
        opacity: 1;
        left: 0px;
    }
}

@-webkit-keyframes fadeSiderCheckListOut {
    0% {
        opacity: 1;
        left: 0px;
    }
    100% {
        left: -500px;
        opacity: 0;
    }
}

@keyframes fadeSiderCheckListOut {
    0% {
        opacity: 1;
        left: 0px;
    }
    100% {
        left: -500px;
        opacity: 0;
    }
}

#root {
    #mainWorkspace {
        #sider-checklist {

            background-color: transparent;
            padding-right: 10px;

            .ant-layout-sider-children {
                background-color: #EEE9F7;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                padding: 8px;
                box-shadow: 0px 6.425755977630615px 18.20630645751953px -5.354796886444092px #34343466;

            }

            .header {
                padding: 4px 8px;
            }

            .title {
                font-size: 20px;
                font-weight: 500;
                margin-top: 8px;
                margin-bottom: 10px;
                padding-left: 10px;
                padding-right: 10px;
            }

            .containerRefresh {
                position: absolute;
                right: 0px;
                top: 0px;
                z-index: 10;
            }

            .ant-tree.ant-tree-show-line {
                background-color: #EEE9F7;
                .ant-tree-switcher {
                    background-color: #EEE9F7;
                    .ant-tree-switcher-line-icon {
                        vertical-align: bottom;
                    }
                }
                .ant-tree-switcher-leaf-line {
                    background-color: #EEE9F7;
                }
                .tree-item {
                    display: flex;
                    align-items: center;
                    border-bottom: solid 1px #b3b3b3;
                    margin-top: 4px;
                    button.verificadoOk {
                        .svg-inline--fa {
                            color: #00C48C;
                        }
                    }
                    button.habilitarCaract {
                        .svg-inline--fa {
                            color: #00C48C;
                        }
                    }
                    button.utilizacaoDesab {
                        .svg-inline--fa {
                            color: #A41A41;
                        }
                    }
                    button.verificadoErro {
                        .svg-inline--fa {
                            color: #A41A41;
                        }
                    }
                    button.faltaVerificar  {
                        .svg-inline--fa {
                            color: #ADADAD;
                        }
                    }
                }

            }

        }

        #sider-checklist.aberto {
            opacity: 1;
        }

        #sider-checklist.fechado {
            opacity: 0;
        }
    }
}

