.webmakers {
}
body.ethimo.webmakers.iy2b-react-modal .modalContent {
    background-color: #DFDFDF;
    .modal-header,
    .box-body,
    .box-actions,
    .modal-footer {
        background-color: #DFDFDF;
    }
    .box-folded-form-field .ant-collapse .ant-collapse-content {
        background-color: #FDFDFD;
    }
}
body.ethimo.webmakers.iy2b-app-body.formFiltroPopUpModal {
    .filtroElements {
        background-color: #DFDFDF;
    }
}
body.ethimo.webmakers.iy2b-app-body {
    #root {
        #mainWorkspace.workspace {
            #mainLayoutWS {
                background-color: #E8E8E8;
            }
            #containerMenuLateral {
                background-color: #666;
                .boxBotaoAbreFecha {
                    .gap-header,
                    .containerBotaoAbreFecha {
                        background-color: #666;
                    }
                }
                #menuLateral {
                    .ant-layout-sider-children {
                        div.logo-container {
                            background-color: #666;
                        }
                    }
                }
            }
            .ant-layout {
                #headerWS.ant-layout-header {
                    background-color: #E8E8E8;
                }
            }
            #containerContent {
                div.ant-tabs.ant-tabs-card {
                    background-color: #E8E8E8;
                }
                div.ant-tabs-nav {
                    div.ant-tabs-nav-wrap {
                        background-color: #E8E8E8;
                    }
                }
                .transacaoContent {
                    .ant-layout {
                        .ant-layout-content {
                            background-color: #E8E8E8;
                        }
                    }
                }
            }
        }
    }
}
