#root .ant-layout .ant-layout-header {
    background-color: #000;
    color: white;

    .titulo {
        text-align: center;
    }
}

#root .ant-layout .ant-layout-footer {
    padding: 30px 24px 6px 24px;
    background-color: #000;
    color: white;
    text-align: right;
    font-size: 12px;
}

body.iy2b-app-body {
    background-color: #000;
}
