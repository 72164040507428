@import "../../assets/css/variaveis";
@import "../../assets/css/breakpoints";

.ant-tree-treenode {

    .ant-tree-title {

        .iy2b-tree-item-title-editor {

            .botao-editar-item,
            .botao-excluir-item,
            .botao-novo-subitem {
                .svg-inline--fa {
                    font-size: 18px;
                }
            }

            span.ant-input-affix-wrapper {
                height: 34px;
            }

            @include md {
                width: 400px;
            }

            @include lg {
                width: 600px;
                font-size: 18px;
            }

            .ant-btn-icon-only {
                vertical-align: unset;
                font-size: 12px;
                width: 24px;
                height: 24px;
            }

            .ant-btn-icon-only > * {
                font-size: 12px;
            }

            @include lg {

                .ant-btn-icon-only {
                    font-size: 16px;
                    width: 32px;
                    height: 32px;
                }

                .ant-btn-icon-only > * {
                    font-size: 16px;
                }

            }

        }

    }

}

