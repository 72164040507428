.boxOptionPod {

}

.optionLabel {
    font-weight: 500;
}

.optionDescription {
    font-weight: 300;
    font-style: italic;
    white-space: break-spaces;
    text-align: justify;
    text-justify: inter-word;
    text-indent: 24px;
}

.Wrapper {
    width: 920px;
    max-width: 100%;
    overflow: hidden;
}

