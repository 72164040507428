.boxAguardandoTransacao {
    margin: 20px 0;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    justify-content: center;
    width: 100%;
    display: inline-flex;

    .loader-transacao {
    }

    h2 {
        margin-top: 0.85em;
        margin-left: 0.5em;
        margin-bottom: 0px !important;
    }
}
