.tooltip-on-icon-box {

    span.anticon-question-circle {
        cursor: help;
    }

}

.tooltip-on-icon-box.margin-top {
    margin-top: 8px;
}
