@import "../../assets/css/breakpoints";
@import "../../assets/css/variaveis";

#meuPlano {

    padding: 6px;
    border-radius: 6px;

    #form {

        padding-bottom: 28px;

        .card-plano-atual {
            .tipoPessoaAtual {
                text-align: center;
                color: #954646;
                font-weight: bold;
            }
        }

        .box-preco:hover {

            border: solid 3px #1890ff !important;
            cursor: pointer;

        }

        .box-preco.selecionado {

            border: solid 3px #1890ff !important;

        }

        .box-preco.o-mesmo {

            border: solid 3px $primaryColorHighlight !important;

        }

        .box-preco {

            border: solid 3px #9c9c9c;
            border-radius: 5px;
            text-align: center;
            padding: 5px;
            min-height: 130px;
            margin: 0px 2px 10px 2px;
            background-color: white;

            .anticon {
                position: absolute;
                right: 18px;
                top: 52px;
                font-size: 16px;
            }

            .nome,
            .formaPagto,
            .total,
            .descricao {
                display: block;
                width: 100%;
            }

            .toolbar {
                button {
                    white-space: normal;
                }
            }

            .nome {
//                border-bottom: solid 1px darkred;
                font-size: 12px;
                @include md {
                    font-size: 14px;
                }
                min-height: 68px;
            }

            .precoBase {
                font-size: 10px;
                text-decoration: line-through;
                font-style: italic;
            }

            .formaPagto {
                font-size: 14px;
                color: #f69320;
                .periodicidade {
                    font-size: 10px;
                    color: #3c3c3c;
                }
            }

            .total {
                font-size: 14px;
                font-weight: normal;
            }

            div.itens {

                text-align: left;

                div.item {
                    margin-top: 0px;
                    padding-top: 0px;
                    margin-bottom: 4px;

                    span {
                        margin-left: 5px;
                    }
                }

            }

        }

    }

    #menu {

        text-align: center;

        button {
            white-space: normal;
        }

        #boxCotasContratadas {
            border-bottom: solid 1px #333;
            padding-bottom: 6px;
        }

        #btnCancelarPlano {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
        }

    }

}

.ant-tooltip-content {

    .tooltip-meu-plano-info {

        .descricao {
            text-align: justify;
            font-weight: bolder;
            min-height: 70px;
        }

        div.itens {

            text-align: left;

            div.item {
                margin-top: 0px;
                padding-top: 0px;
                margin-bottom: 4px;

                span {
                    margin-left: 5px;
                }
            }

        }

    }

}

