@import "../../assets/css/variaveis";
@import "../../assets/css/breakpoints";

body.iy2b-react-modal {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 0px 0px 0px 0px;
        border-radius: 6px;
        background-color: #FFF;

        .modal-header {
            text-align: center;
            background-color: $primaryColorDark;
            color: $primaryBgColor;
            font-weight: bold;
            font-size: 16px;
            height: 52px;
            div {
                display: inline-block;
            }
            div.top-bar {
                position: absolute;
                right: 10px;
                .botao-fechar {
                    background-color: #D3CAEB;
                    .svg-inline--fa {
                        color: #F8F8F8;
                    }
                }
            }
        }

        .box-body {
            background-color: $primaryBgColor;
            padding: 15px;
            height: calc(100% - 94px);
            z-overflow-x: auto;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .box-body.has-actions {
            height: calc(100% - 144px);
        }

        .box-actions {
            text-align: right;
            display: flex;
            background-color: $primaryBgColor;
            padding: 0px 26px;
            color: $primaryBgColor;
            font-weight: bold;
            font-size: 16px;
            height: 52px;
        }

        .box-actions.hidden {
            display: none;
            height: 0px;
            padding: 0px;
        }

        .modal-footer {
            background-color: $primaryColorDark;
            padding: 5px;
        }

    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0px;
        width: 100%;
    }

}
