$botaoBorderColor: #5640AC;

div.ant-menu-submenu.ant-menu.popup-ws-menu-lateral {
    ul {
        li {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}

#root {
    #mainWorkspace {
        #containerMenuLateral {
            background-color: transparent;
            #menuLateral {
                box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #6C4EBC;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 30px;
                border-top-left-radius: 0px;
                border-top-right-radius: 30px;
                overflow: hidden;
                padding: 0px;
                background-color: #EEE9F7;
                ul.ant-menu.ws-menu-lateral {
                    background-color: #EEE9F7;
                    border: none;
                    .ant-menu-submenu {
                        .ant-menu-item-icon {
                            font-size: 20px;
                        }
                    }
                    .ant-menu-item-group-title {
                        text-align: center;
                    }
                    li.ant-menu-item.ant-menu-item-selected {
                        background-color: #EEE9F7;
                    }
                    li.ant-menu-submenu.ant-menu-submenu-open {
                        background-color: #E3F7EE;
                    }
                }
                .logo-container {
                    height: 80px;
                    background-color: #00C48C;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }
                .boxBotaoLogout {
                    height: 96px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .botaoLogout {
                        height: 48px;
                        width: 48px;
                        svg {
                            font-size: 20px;
                        }
                    }
                    .botaoLogout:hover {
                        border: none;
                    }
                    .label {
                        height: 18px;
                        transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
                    }
                }
            }
            .boxBotaoAbreFecha {
                .gap-header {
                    height: 80px;
                    background-color: #00C48C;
                }
                .containerBotaoAbreFecha {
                    border-left: solid 1px #9982D1;
                    background-color: #EEE9F7;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .botaoFechar ,
                    .botaoAbrir {
                        border-top: solid 1px $botaoBorderColor;
                        border-bottom: solid 1px $botaoBorderColor;
                        border-right: solid 1px $botaoBorderColor;
                        border-left: none;
                        padding: 8px 0px;
                        width: 24px;
                        height: 42px;
                        text-align: left;
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 10px;
                        border-top-left-radius: 0px;
                        border-top-right-radius: 10px;
                    }
                }
            }
        }
    }
}
