
body.iy2b-react-modal .modalOverlay.backdrop-popup-ver-debug {
    z-index: 1000;
}

.popup-ver-debug {

    .box-body {
        padding: 0px !important;

        .body-popup-ver-debug {
            width: 100%;
            .ant-menu.ant-menu-root.ant-menu-horizontal {
                width: 100% !important;
            }
        }

    }

}

.table-debug-viewer {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
}

