@import "../../../assets/css/variaveis";
@import "../../../assets/css/breakpoints";


div.iy2b-pro-field.iy2b-auto-complete.close-right-side {

    .ant-select {
        .ant-select-selector {
            border-right: solid 1px #d9d9d9;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

}

div.iy2b-pro-field.iy2b-auto-complete {

    display: flex;

    .ant-form-item:first-child {
        width: calc(100% - 42px);
        display: inline-block;
    }

    .ant-select {
        .ant-select-selector {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: none !important;
        }
    }

    .row-icon-search {

        .ant-form-item-label {
            display: block;
            padding: 0 0 8px;
        }

        button.ant-btn {
            border: solid 1px #d9d9d9;
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: -6px;
            padding: 0px 6px;
            font-weight: 700;
            //padding-left: 4px;
            //padding-right: 0px;
            .ant-btn-icon {
                margin: 0px;
                .svg-inline--fa {
                    color: #8D8D8D;
                }
            }
        }

        button.ant-btn:hover,
        button.ant-btn:focus {
            color: #d9d9d9;
            border: solid 1px #d9d9d9;
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

    }

}

div.iy2b-pro-field.iy2b-auto-complete:hover,
div.iy2b-pro-field.iy2b-auto-complete:active,
div.iy2b-pro-field.iy2b-auto-complete:focus {
    button.ant-btn {
        border-color: #9982D1;
        .ant-btn-icon {
            .svg-inline--fa {
                color: #343434;
            }
        }
    }
}

div.iy2b-pro-field.not-visible {
    display: none;
}
