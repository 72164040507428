@import "../../assets/css/variaveis";
@import "../../assets/css/breakpoints";

.iy2b-pro-form-button.not-visible {
    display: none;
}

.iy2b-pro-form-button {

    .ant-form-item-label {
        display: block;
    }

}

.row-item-repeater {
    .iy2b-botao-acao {
        margin-top: 30px;
    }
}
