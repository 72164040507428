@import "../../assets/css/variaveis";
@import "../../assets/css/breakpoints";

#headerWS {
    .headerTBBackdropLoading {
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.20);
        margin-left: 12px;
        top: 0px;
        left: 0px;
        position: absolute;
        border-radius: 12px;
    }
    .pesquisaWSLoadingPosition {
        position: relative;
        height: 64px;
        .pesquisaWSBackdropLoading {
            width: 300px;
            @include xl {
                width: 340px;
            }
            @include xxl {
                width: 380px;
            }
            height: 64px;
            z-index: 9999;
            background-color: rgba(0, 0, 0, 0.20);
            top: 0px;
            left: 0px;
            position: absolute;
            border-radius: 40px;
        }
    }
}

#containerContent {

    .trnLoadingPosition {
        width: 100%;
        position: absolute;
        left: 0px;
    }

    .trnBackdropLoading {
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.20);
        width: 100%;
        top: 0px;
        left: 0px;
        position: absolute;
        border-radius: 6px;
    }

}

#mainWorkspace.v2 {

    .wrkspLoadingPosition {

        width: 100%;
        position: absolute;
        left: 0px;

        .wrkspBackdropLoading {
            z-index: 9999;
            background-color: rgba(0, 0, 0, 0.20);
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }

    }

}

.workspace {

    .wrkspLoadingPosition {

        width: 100%;
        position: relative;

        .wrkspBackdropLoading {
            z-index: 9999;
            background-color: rgba(255, 255, 255, 0.65);
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }

    }

}

.login {

    #backdropLoading {
        background-color:  rgba(0, 0, 0, 0.20);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 100;
    }

}
