@import "./breakpoints";

.iy2b-avatar-com-edicao-container.open {
}

.iy2b-avatar-com-edicao-container.close {
}


.iy2b-avatar-com-edicao-container {

    .ant-avatar:hover {
        border: solid 1px #333;
    }

    div.helpAvatarMobile {

        @include md {
            display: none;
        }

    }

}

body.avatarEdicaoDropdownModal {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2000;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 0px 0px 0px 0px;
        top: 0px;
        z-index: 2001;
        background-color: white;

        .header {
            font-size: 20px;
            padding: 4px 10px;
            border-bottom: solid 1px #CCC;
        }

        .content {

            text-align: center;
            padding: 5px 10px;

            .escolherArquivoBox {
                padding: 4px 8px;
            }

            .ReactCrop__image {
                width: 310px;
                height: 310px;
            }

        }

        .footer {
            padding: 4px 10px;
            border-top: solid 1px #CCC;
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
            align-content: center;
            flex-wrap: nowrap;
            width: 100%;
            flex-direction: row;
        }
    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}
