@import "../../assets/css/breakpoints";

#checklistProcessoDropdown {

    height: 64px;

    .containerBtnTeacher {
        background: transparent;
        position: relative;
        height: 64px;
        padding: 0px 10px;
        border: solid 1px black;
    }

    .containerBtnTeacher.aberto {
        background-color: white;
    }

    #btnTeacher {
        height: 44px;
        line-height: 44px;
        position: relative;
        background-color: black;
        margin: 15px 0px 0px 0px;
        border-radius: 0px;
        color: white;

        a {
            color: white;
            display: block;

            .containerIcon {

                font-size: 24px;

                @include md {
                    font-size: 30px;
                }
            }

        }

        a:hover, a:focus {
            color: #999999;
        }
    }

}

.dropdownSearchChecklistProcesso {
    z-index: 2002;
}

body.checklistProcessoModal {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2000;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 0px 0px 0px 0px;
        z-index: 2001;
        top: 64px;

        .containerDropdownCkL {
            display: inline-block;
            padding: 20px;
            background-color: #000;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-color: #aaa;
            border-left-color: #aaa;
            border-right-color: #aaa;
            border-width: 1px;
            border-style: solid;
            margin-top: 2px;
            .btnFechar {
                display: block;
                position: absolute;
                .containerIconFechar {
                    background-color: #fafafa;
                    color: #3c3c3c;
                }
            }

        }

    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}
