@import "variaveis";

#containerContent {

    background-color: #EEE9F7;

    div.ant-tabs.ant-tabs-card {
        background-color: #EEE9F7;
    }

    .ant-tabs-nav {
        .ant-tabs-nav-wrap {
            background-color: #e9e9e9;
        }
    }

    .ant-tabs.ant-tabs-left {

        .ant-tabs-nav {

            .ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active {
                border-right: solid 1px #954646;
            }

        }

    }

    .transacaoContent {
        overflow-x: auto;
        background-color: white;

        .ant-layout-content.tc-body {
            min-height: 100%;
            height: 100%;
            div.ant-layout:first-child {
                min-height: 100%;
                height: 100% !important;
            }
        }
        padding-left: 0px;
        padding-right: 8px;

    }

    .trnLoadingPosition {
        width: 100%;
        position: absolute;
        left: 0px;
    }

    .wsLoadingPosition {
        width: 100%;
        position: relative;
    }

    .trnBackdropLoading {
        z-index: 9999;
        background-color:  rgba(0, 0, 0, 0.20);
        width: 100%;
        top: 0px;
        left: 0px;
        position: absolute;
    }

}

.ant-drawer-content.help-drawer {
    .ant-drawer-body {
        padding: 0px;
    }
}
