.body-editar-config-imob {
    .title {
        label {
            display: block;
            text-align: center;
            width: 100%;
            font-weight: 700;
        }
    }
    #colAcoesLoc {
        border-left: solid 1px #CCC;
    }
    #colAcoesVen, #colTratamentoLeads {
        border-right: solid 1px #3c3c3c;
    }
}
