$primaryColor: #1890ff;
$primaryColorHover: #0067c7;
$primaryColorLight: #D5D5D5;
$primaryColorDark: #071e38;
$primaryColorHighlight: lightseagreen;
//$primaryColorRowEveryOther: rgba(24,144,255,0.25);
$primaryColorRowEveryOther: #e0e1a8;
$tableRowSelect: #E6F7FF;
//$tableRowSelect: #e0e1a8AF;
$primaryBgColor: #eef5f8;

