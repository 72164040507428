div.ant-row.box-botao-upload {

    .text-url {
        display: none;
    }

    .ant-upload {
        width: 100%;
        button {
            width: 100%;
        }
    }

}

div.ant-row.box-botao-upload.width-xs {
    width: 104px;
}

div.ant-row.box-botao-upload.width-sm {
    width: 216px;
}

div.ant-row.box-botao-upload.width-md {
    width: 328px;
}

div.ant-row.box-botao-upload.width-lg {
    width: 440px;
}

div.ant-row.box-botao-upload.width-xl {
    width: 552px;
}
