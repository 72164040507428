@import "../../assets/css/breakpoints";

.apresentacaoModal {

    .body {

        position: absolute;
        top: 0px;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 2020;
        background-color: rgba(0, 0, 0, 0.15);

        .display {
            position: absolute;
            top: 0px;
            bottom: 68px;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: justify;

            .seta {
                margin: 0px 40px 0px 12px;

                button {
                    background-color: #38c593;
                    border-color: #38c593;
                }

                button:hover, button:active, button:focus {
                    color: #e6f5f0;
                }

            }

            .etapa {
                font-size: 22px;
                border-radius: 5px;
                padding: 20px;
                background-color: #e6f5f0;
                border: solid 2px #38c593;
            }

            .inicial, .final {
                margin: 240px 180px;
            }

            .perfilUsr {
            }

        }

        .footer {
            position: absolute;
            bottom: 16px;
            text-align: center;
            width: 100%;

            button {
                background-color: #38c593;
                border-color: #38c593;
            }

            button:hover, button:active, button:focus {
                color: #e6f5f0;
            }

        }

    }

}

body.apresentacaoModal {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: 2010;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 0px 0px 0px 0px;
        z-index: 2015;
        top: 64px;

        .containerApresentacaoModal {
        }

    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}
