@import "../../assets/css/variaveis";
@import "../../assets/css/breakpoints";

body.popUpWizNovaEmpresa {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 0px 0px 0px 0px;

        .modal-header {
            text-align: center;
            background-color: $primaryColorDark;
            padding: 10px;
            color: $primaryBgColor;
            font-weight: bold;
            font-size: 16px;
            height: 52px;
            div.top-bar {
                position: absolute;
                right: 10px;
            }
        }

        .box-body {
            background-color: $primaryBgColor;
            padding: 15px;

            .ant-pro-steps-form-container {
                min-width: 620px;
                @include lg {
                    min-width: 720px;
                }
            }

            .containerStepCertDig {
                max-width: 720px;
                overflow-y: auto;
                overflow-x: hidden;
                height: 310px;
                @media (min-height: 768px) {
                    height: 400px;
                }
            }

            .containerStepCadEmpresa {
                overflow-y: auto;
                overflow-x: hidden;
                height: 320px;
                @media (min-height: 768px) {
                    height: 400px;
                }
            }

            .ant-pro-steps-form-container {
                .ant-space.ant-space-horizontal.ant-space-align-center {
                    margin-top: 8px;
                }
            }

            .ant-upload-drag {
                border: solid 1px #CCC;
                padding: 30px;
            }

            .rowListaReqs {

                margin:  5px;

            }

            .rowCertDig, .rowSenha, .rowInfoCertD, .rowAceite {

                margin: 5px 5px 15px 5px;

            }

        }

        .modal-footer {
            background-color: $primaryColorDark;
            padding: 5px;
        }

    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}
