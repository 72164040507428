.ant-drawer-content.notifications-drawer {
    .ant-drawer-header {
        background-color: #000;
        .ant-drawer-header-title {
            button.ant-drawer-close {
                color: #fff;
            }
            .ant-drawer-title {
                color: #fff;
            }
        }
    }
    .ant-drawer-body {
        .scroller {
            .notificacao {
                .ant-list-item-meta-title {
                    margin-bottom: 4px;
                }
                .ant-list-item-meta {
                    margin-bottom: 6px;
                }
                .descricao {
                }
                .ant-list-item-action {
                    margin-top: 6px;
                    text-align: right;
                    .svg-inline--fa {
                        font-size: 20px;
                    }
                    .botaoSolucao {
                        .svg-inline--fa {
                        }
                    }
                    .botaoExcluir {
                        .svg-inline--fa {
                            color: #E00000;
                        }
                    }
                }
            }
        }
    }
}
