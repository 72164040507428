@import "../../../../framework/assets/css/variaveis";

.box-form-field-repeater {

    .box-row-header-actions {

        .row-header-actions {
            padding: 0 0 8px;
            line-height: 1.5715;
        }

        margin-bottom: 12px;

    }

    .ant-row.ant-form-item {
        margin-bottom: 12px;
    }

    .ant-row.row-item-repeater {
        .box-container-fields {
            padding-left: 16px !important;
        }
        padding-bottom: 12px;
        .row-separator {
            margin-top: 10px;
        }
    }

}

.box-form-field-repeater.bffr-disabled {

    .box-row-header-actions {
        display: none;
    }

}
