@import "../../assets/css/breakpoints";

.login {

    height: 100vh;
    background-color: #F8F8F8;

    .ant-row.full-height {
        height: 100vh;
        background-color: #F8F8F8;
    }

    .versaoPlataforma {
        position: absolute;
        bottom: 10px;
        right: 16px;
    }

    .col-login {
        height: 100%;
        background-color: #F8F8F8;
        padding: 0px !important;
        .box-login {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            form {
                width: 369px;
                height: 640px;
                background-color: #F8F8F8;
                #fazerLogin {
                    color: #141414;
                    font-weight: 700;
                    font-size: 36px;
                }
                #dicaLogin {
                    color: #ADADAD;
                    font-weight: 400;
                    font-size: 16px;
                }
                #separadorOU {
                    margin: 15px 0px;
                    display: flex;
                    align-items: center;
                    .antes, .depois {
                        width: calc(50% - 20px);
                        border-top: solid 1px #ADADAD;
                    }
                    .label {
                        color: #ADADAD;
                        width: 40px;
                        text-align: center;
                    }
                }
                .ant-input-affix-wrapper {
                    height: 48px;
                }
                input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #252525;
                    opacity: 1; /* Firefox */
                }

                input:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #252525;
                }

                input::-ms-input-placeholder { /* Microsoft Edge */
                    color: #252525;
                }

                .box-login-provedores {
                    width: 100%;
                    height: 100%;
                    margin-top: 20px;
                    button {
                        border-radius: 10px;
                        height: 50px;
                    }
                }

                button.botaoLogin, button.botaoLogin:focus {
                    background-color: #8267C6;
                    border-color: #8267C6;
                    color: #EEE9F7;
                }
                button.botaoLogin:hover {
                    background-color: #EEE9F7;
                    border-color: #8267C6;
                    color: #8267C6;
                }
                button.esqueceuASenha {
                    text-transform: unset !important;
                }
                .termosUso {
                    margin-top: 16px;
                    font-weight: 400;
                    a {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .col-lateral-login {
        .box-lateral-login {
            height: 100%;
            border-bottom-left-radius: 200px;
            background-color: #00b978; /* Used if the image is unavailable */
            box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #343434;
            .box-lateral-login-logo {
                height: 50%;
                background-image: url("../../../assets/images/background-inicial.png"); /* The image used */
                background-position: top; /* Center the image */
                background-repeat: no-repeat; /* Do not repeat the image */
                background-size: 100% auto; /* Resize the background image to cover the entire container */
                @include lg {
                    background-size: cover; /* Resize the background image to cover the entire container */
                    background-position: center; /* Center the image */
                }
            }
            .box-lateral-login-texto {
                text-align: center;
                margin: 0px 20%;
                padding: 20px;
                border-radius: 20px;
                border: solid 1px #FFFFFFA6;
                color: #FFFFFF;
                font-size: 16px;
                a {
                    color: #FFFFFF;
                    font-size: 24px;
                }
            }
        }
    }

    #backdropLoading {
        background-color:  rgba(0, 0, 0, 0.20);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 101;
    }

    .ant-image {
        .ant-image-img {
        }
    }

}

body.iy2b-ios {
    .login {
        .ant-layout-content {
            background-size: 100% auto; /* Resize the background image to cover the entire container */
        }
    }
}


.drawer-orientacoes-prov-id {

    .ant-drawer-content-wrapper {

        width: 335px !important;

        @include md {

            width: 670px !important;

        }
        .ant-drawer-body {
            .orientacoes-prov-id {

                ol {
                    margin: 10px 5px;

                    li {
                        margin-bottom: 5px;
                        font-size: 16px;

                        ul {
                            margin: 5px;
                            li {
                                font-style: italic;
                            }
                        }
                    }
                }
            }
        }

    }

}

