.iy2b-range-digit {

    .ant-form-item-control-input-content {
        /*
        border: solid 1px #d9d9d9;
        border-radius: 2px;
        transition: border 0.3s, box-shadow 0.3s;
        */

        span.anticon.anticon-swap-right {
            color: rgba(0, 0, 0, 0.25);
        }

        span.icon-clear {
            color: rgba(0, 0, 0, 0.25);
            margin-right: 2px;
        }

        span.icon-clear.hidden {
            display: none;
        }

    }

}
