body.popUpIFrameModal {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2000;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 0px 0px 0px 0px;
        top: 20px;
        z-index: 2001;

        .boxIFrame {
            z-index: 2002;
            color: #fff;
            text-align: center;
            background: #DcDcDc;
            .iframe-peca {

            }
        }

        .footer {
            text-align: end;
            background-color: #3c3c3c;
            padding: 10px;
            z-index: 3000;
            button {
                z-index: 3001;
                span {
                    z-index: 3002;
                }
            }
        }

    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}


