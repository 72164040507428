@import "./breakpoints";

#empresaDropdown.open {
    .containerBtnEmpresa {
        background: white;
        z-index: 2049;
        #btnEmpresa {
            z-index: 2050;
            border: solid 1px #999999;
            a {
                color: #999999;
            }
        }
    }
}

#empresaDropdown.close {
    .containerBtnEmpresa {
        background: black;
        z-index: 10;
        #btnEmpresa {
            z-index: 10;
        }
    }
}

#empresaDropdown {

    height: 64px;
    width: 300px;

    @include md {
        width: 350px;
    }

    .containerBtnEmpresa {
        position: relative;
        height: 64px;
        padding: 0px 10px;
        border: solid 1px black;
    }

    #btnEmpresa {
        height: 44px;
        line-height: 44px;
        position: relative;
        margin: 5px 0px 0px 0px;
        border-radius: 0px;

        a {
            font-size: 14px;
            color: black;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            div.conteudoBotaoEmpresa {
                margin-left: 6px;
                width: 310px;
                div.noEmpresa {
                    white-space: nowrap;
                    overflow: hidden;
                    display: inline-flex;
                    font-size: 22px;
                }
                div.noPlano {
                    white-space: nowrap;
                    overflow: hidden;
                    display: inline-flex;
                    font-size: 14px;
                }
            }
            padding-top: 8px;
            background-color: black;
            color: white;
            padding-bottom: 5px;
            border-bottom: solid 1px white;
            @include md {
                padding-top: 0px;
            }
            .containerCaret {
                width: 34px;
                text-align: end;
            }
        }

        a:hover, a:focus {
            color: #999999;
        }
    }

}


body.empresaDropdownModal {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2000;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 0px 0px 0px 0px;
        top: 64px;
        z-index: 2001;

        .header {
            margin: 0px 0px;
            padding: 6px 6px;
            font-size: 16px;
            font-weight: bold;
            font-style: italic;
            background-color: white;
        }

        .scroller {
            height: 400px;
            overflow-x: auto;
            background-color: white;
            border-radius: 0px;
            padding-top: 5px;
            .ant-list {
                .ant-list-item {
                    padding: 6px 0px;
                    border-bottom: none;
                    a.opcao {
                        width: 100%;
                        padding: 0px 5px 0px 10px;
                        color: black;
                        font-size: 18px;
                        display: flex;
                        align-items: baseline;
                        .ant-image {
                            padding-right: 4px;
                            img {
                                border-radius: 20px;
                            }
                        }
                    }
                }
                .ant-list-item.active {
                    background: rgb(45,45,45);
                    border-left: solid 3px antiquewhite;
                    a.opcao {
                        color: white;
                    }
                }
                .ant-list-item:hover {
                    background-color: rgb(90,90,90);
                    a.opcao {
                        color: white;
                    }
                }
            }
        }
    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}
