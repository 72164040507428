@import "../../assets/css/variaveis";
@import "../../assets/css/breakpoints";

body.popUpWizNovoUsuario {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 0px 0px 0px 0px;

        .modal-header {
            text-align: center;
            background-color: $primaryColorDark;
            padding: 10px;
            color: $primaryBgColor;
            font-weight: bold;
            font-size: 16px;
            height: 52px;
            div.top-bar {
                position: absolute;
                right: 10px;
            }
        }

        .box-body {
            background-color: $primaryBgColor;
            padding: 15px;

            .ant-pro-steps-form-container {
                min-width: 620px;
                @include lg {
                    min-width: 720px;
                }
            }

            .containerStepAcessos {
                overflow-y: auto;
                overflow-x: hidden;
                height: 330px;
                @media (min-height: 768px) {
                    height: 400px;
                }

                .ant-transfer-list {
                    width: 47%;
                    height: 186px;
                }

            }

            .containerStepDados {
                overflow-y: auto;
                overflow-x: hidden;
                height: 330px;
                @media (min-height: 768px) {
                    height: 400px;
                }
            }

            .ant-pro-steps-form-container {
                .ant-space.ant-space-horizontal.ant-space-align-center {
                    margin-top: 8px;
                }
            }

        }

        .modal-footer {
            background-color: $primaryColorDark;
            padding: 5px;
        }

    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}
