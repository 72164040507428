.formFiltroContainer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    .botaoFiltrar {
        margin-right: 36px;
    }
}

div.ant-pro-table.iy2b-table {
    .formFiltroContainer {
        .botaoFiltrar {
            margin-right: 0px;
        }
    }
}

body.iy2b-app-body.formFiltroPopUpModal {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 0px 0px 0px 0px;
        z-index: 101;
        background-color: transparent;
        border: none;

        .filtroElements {

            .ant-card-body {
                .header {
                    padding: 4px 10px;
                    color: #00B978;
                    font-size: 24px;
                    font-weight: 600;
                }
            }

            .fields-filter {
                overflow-y: auto;
                overflow-x: hidden;
            }

            .rowAlternaExtra {
                width: 100%;
                justify-content: center;
                margin-top: 5px;
                margin-bottom: 15px;
                button.alternaExtra {
                    z-index: 5;
                }
                button {
                    width: 100%;
                }
            }

            .rowAcoesFiltro {
                width: 100%;
                justify-content: space-between;
                button {
                    margin-top: 0px;
                    width: 100%;
                }
            }

            div.extraContainer {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s, opacity 500ms linear;
                .extraElements {
                    display: none;
                }
            }

            div.extraContainer.exibe {
                visibility: visible;
                opacity: 1;
                .extraElements {
                    display: block;
                }
            }

            div.extraContainer.nao-exibe {
                visibility: hidden;
                opacity: 0;
                .extraElements {
                    display: none;
                }
            }

        }

    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}
