.box-iy2b-date-range-picker {

    .ant-picker.ant-picker-range {
        width: 100%;
    }

    button {
        padding: 8px 6px;
    }

}
