@import "variaveis";

#usuarioDropdown.open {
    a {
        background: white;
        z-index: 2001;
    }
}

#usuarioDropdown.close {
    background: transparent;
    a {
        z-index: 10;
    }
}

.workspace.v2 {
    #usuarioDropdown {
        a {
            padding-left: 4px;
            padding-right: 4px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }
    }
}

#usuarioDropdown {

    height: 64px;
    position: relative;

    a {
        background: transparent;
        position: absolute;
        display: inline-block;
        left: 0px;
        padding-left: 8px;

        #usrAvatar {
            color: #000;
            margin-top: 0px;
            margin-right: 0px;
        }

        #caretAvatar {
            padding-left: 4px;
        }

    }

}

body.usuarioDropdownModal {

    .modalOverlay {
        position: fixed;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2000;
    }

    .modalContent {
        position: absolute;
        inset: 0px;
        overflow: auto;
        outline: none;
        padding: 0px 0px 0px 0px;
        top: 64px;
        z-index: 2001;

        .boxLabel {
            border-top-left-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $primaryBgColor;
            #noPessoaUsr {
                margin-top: 10px;
                margin-left: 10px;
                font-size: 20px;
                font-weight: bolder;
                font-style: italic;
            }
            .btnFechar {
                margin-top: 10px;
                margin-right: 10px;
            }
        }

        #adSpace {
            border-top: solid 1px #3c3c3c;
            height: 240px;
            .peca {
                height: 238px;
                color: #fff;
                text-align: center;
                background: #9c9c9c;
            }
        }

        .scroller {
            overflow-x: auto;
            background: white;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;

            .container {
                background-color: $primaryBgColor;

                #btnEncerrar {
                }

                #botoes {
                    .boxBotao {
                        display: inline-block;
                        width: 50%;
                    }
                }

                .botao {
                    font-size: 20px;
                    background-color: white;
                    margin: 4px 4px;
                    border: solid 1px #666;
                    padding: 8px 8px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    align-content: center;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    min-height: 80px;
                    border-radius: 6px;

                    .icone {
                        margin-right: 10px;
                    }
                    .label {
                        display: block;
                    }
                }

            }

            .ant-list {

                .ant-list-item.divisor {
                    padding: 2px 0px;
                    .ant-divider {
                        margin: 6px 0px;
                        border-top: solid 1px silver;
                    }
                }

                .ant-list-item {
                    padding: 6px 0px;
                    border-bottom: none;

                    a.opcao {
                        width: 100%;
                        padding: 0px 5px 0px 10px;
                        color: black;
                        font-size: 20px;

                        div {

                            display: inline-flex;

                            .icone {
                                padding-right: 6px;
                                font-size: 24px;
                            }

                        }

                    }

                }

            }

        }

    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.modalOverlay {
        top: 0;
        width: 100%;
    }

}



.ant-modal.usuarioDropdown {

    position: absolute;
    top: 20px;
    right: 20px;

    .ant-modal-body {

    }

}
