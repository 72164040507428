@import "variaveis";
@import "breakpoints";
@import "skins/ethimo.scss";
@import "skins/webmakers.scss";

body.iy2b-app-body {
    font-family: 'Raleway', sans-serif;
    .ant-statistic-content {
        font-family: 'Raleway', sans-serif;
    }
    background-color: $primaryBgColor;
    @include md {
        overflow: hidden;
    }
    .ant-col.full-height {
        height: 100%;
    }
    .ant-input-number-borderless {
        background-color: #fff;
    }
    .ant-btn.ant-btn-default.btn-alternative {
        background-color: #666;
        color: #FFF;
    }
    .ant-btn.ant-btn-default.btn-alternative:hover,
    .ant-btn.ant-btn-default.btn-alternative:focus,
    .ant-btn.ant-btn-default.btn-alternative:active,
    {
        background-color: #333 !important;
        color: #FFF !important;;
        border-color: #333 !important;
    }
    .iy2b-pro-field-xs {
        width: 104px;
        max-width: 100%;
    }
    .iy2b-pro-field-sm {
        width: 216px;
        max-width: 100%;
    }
    .iy2b-pro-field-md {
        width: 328px;
        max-width: 100%;
    }
    .iy2b-pro-field-lg {
        width: 440px;
        max-width: 100%;
    }
    .iy2b-pro-field-xl {
        width: 552px;
        max-width: 100%;
    }
    .iy2b-pro-field-xxl {
        width: 600px;
        max-width: 100%;
    }
}

body.iy2b-app-body.iy2b-ios {

}

body.iy2b-app-body.iy2b-react-modal.iy2b-ios {
    .ReactModal__Overlay {
        height: 100%;
    }
}


.ant-col.ant-col-hidden {
    display: none;
}

.ant-row.col-separator {
    .ant-col.col-separator-right {
        position: relative;
        padding-right: 16px !important;
        div.col-separator-right {
            position: absolute;
            top: 6px;
            right: 0px;
            border: solid 2px #EEE9F7;
            height: calc(100% - 24px);
            width: 0px;
        }
    }
    .ant-col.col-gap-separator-right {
        padding-left: 16px !important;
    }
}

.ant-image-preview-root {
    .ant-image-preview-mask {
        background-color: rgba(0, 0, 0, 0.85);
    }
    .ant-image-preview-wrap {
        .ant-image-preview-body {
            .ant-image-preview-operations {
                background-color: rgba(0, 0, 0, 0.55);
                .ant-image-preview-operations-operation {
                    background-color: rgba(0, 0, 0, 0.95);
                }
            }
        }
    }
}

.iy2b-footer-toolbar {
    position: absolute;
    bottom: -40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px 0px 0px;
}

div.ant-layout {

    min-height: 100%;

    .ant-col.display-contents {
        display: contents;
    }
    .ant-col.align-end {
        text-align: right;
    }

    .ant-col.align-center {
        text-align: center;
    }

    .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
        right: 20px;
    }

    .ant-layout-content {
        background-color: $primaryBgColor;

        .ant-form {
            background-color: $primaryBgColor;
        }

    }

}

div.ant-modal-root {

    .ant-modal {

        .ant-modal-content {
            height: 100%;
        }

    }

}

.ant-tabs {

    .ant-tabs-content-holder {
        padding: 0px 2px 0px 0px;
    }

    .ant-tabs-nav {

        margin-bottom: 6px;

        .ant-tabs-nav-list {

            .ant-tabs-tab:hover {
                color: #954646;
            }

            .ant-tabs-tab {
                background-color:  #CFCFCF;
                color: #666;
                padding: 8px 16px;
                margin: 0px 4px 0px 0px;
            }

            .ant-tabs-tab.ant-tabs-tab-active {
                background-color:  white;
                border-bottom: solid 1px #954646;
                border-top-right-radius: 20px;

                .ant-tabs-tab-btn {
                    color: #954646;
                }

            }

            .ant-tabs-ink-bar {
                background: #954646;
            }

        }

    }

}

#root div.ant-layout,
div.ant-layout, {
    .ant-card.card-box-filtro {
        box-shadow: 0px 6.411559581756592px 18.166086196899414px -5.342966079711914px #3434341F;
        .ant-card-body {
            padding: 0px;
            .ant-form {
                border-radius: 8px;
                background-color: #FFF !important;
            }
        }
        margin-bottom: 8px;
    }
}

.ant-picker-panels {

}

#root div.ant-layout, div.ant-modal-root, div.ant-drawer-open, div.ReactModalPortal {

    --antd-wave-shadow-color: $primaryColor;

    .ant-card.card-filtro {
        border: none;
        border-radius: unset;
        box-shadow: none;
        .ant-card-body {
            padding: 0px;
        }
    }

    .ant-card-body {
        padding: 16px;
    }

    .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
        .ant-col.ant-form-item-control {
            position: relative;
        }
    }
    .ant-form-item-explain {
        display: none;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
        z-index: 99;
        position: relative;
        display: block;
        div {
            background-color: whitesmoke;
            position: absolute;
            padding: 4px 6px;
            border-radius: 5px;
        }
    }

    div.pro-form-group-spacer > div.ant-pro-form-group {
        margin-bottom: 8px;
    }

    div.ant-pro-form-group {
        div.ant-space.ant-pro-form-group-container {
            column-gap: 16px !important;
        }
    }

    div.box-form-field-repeater {

        div.title {
            font-weight: bold;
            margin-top: 8px;
            margin-bottom: 4px;
        }

    }

    .ant-transfer-list {

        .ant-transfer-list-body {

            background-color: #fafafa;

            .ant-transfer-list-content-item-remove {

                .anticon.anticon-delete {

                    color: red;

                }

            }

        }

    }

    .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {

        margin-bottom: 6px;

    }

    .ant-descriptions-item.destaque {

        .ant-descriptions-item-content {
            font-weight: bolder;
        }

    }

    .ant-radio-button-wrapper:hover {
        color: $primaryColorHover;
    }

    .ant-btn-link {
        color: #454545;
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background: $primaryColor;
        border-color: $primaryColor;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
        background-color: $primaryColor;
    }

    .ant-layout-header {
        background-color: $primaryColor;
    }

    .ant-layout-sider {
        background-color: $primaryColorLight;
    }

    .ant-btn:hover, .ant-btn:focus {
        color: $primaryColor;
        border-color: $primaryColor;
    }

    .ant-btn.ant-btn-text:hover, .ant-btn.ant-btn-text:focus {
        color: rgba(0, 0, 0, 0.85);
        border-color: transparent;
    }

    .ant-btn.ant-btn-link:hover, .ant-btn.ant-btn-link:focus {
        /*background-color: $primaryColorLight;*/
        background-color: transparent;
        border: none;
    }

    .ant-btn-primary {
        background-color: $primaryColor;
        border-color: $primaryColor;
    }

    .ant-btn-primary:hover, .ant-btn-primary:focus {
        color: white;
        background: $primaryColorHover;
        border-color: $primaryColorHover;
    }

    .ant-switch-checked {
        background-color: $primaryColorHighlight;
    }

    .ant-form {
        padding: 4px 10px;
    }

    .ant-row.ant-form-item.ant-form-item-has-success {
        margin-bottom: 8px !important;
    }

    .ant-row.ant-form-item, .ant-form-item {
        margin-bottom: 8px !important;
    }

    .ant-list.iy2b-list {

        li:nth-child(2n) {
            background-color: #e0e1a8;
        }

    }

    .ant-list-split .ant-list-item {
        border-bottom: 1px solid #696969;
    }

    .ant-pro-table.iy2b-table.XXXX.iy2btable.scss {

        .ant-table-wrapper {

            thead {
                tr {
                    th {
                        background-color: #333;
                        color: white;
                    }
                }
            }

            tr:nth-child(2n) td {
                background-color: #e0e1a8;
            }

        }

    }

}
