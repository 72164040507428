@import "../../assets/css/breakpoints";

#meuPerfil {

    padding: 6px;
    border-radius: 6px;

    .iy2b-avatar-com-edicao-container {

        @include md {
            position: absolute;
            width: 100%;
        }

    }

}
