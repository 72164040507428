@import "./breakpoints";

#backdropLoadingSinglePage {
    background-color:  rgba(0, 0, 0, 0.20);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 101;

    @include sm {
        min-height: 1024px;
    }

    @include lg {
        min-height: 1200px;
    }

}
